import * as i from 'types';
import { useQuery } from '@tanstack/react-query';

import { contentfulClient } from 'services';

export const fetchInstructors = async (
  locale: string,
  filters?: {
    location?: string;
  },
  preview?: boolean,
  select?: string[],
) => {
  return contentfulClient(preview).getEntries<i.TypeInstructorFields>({
    content_type: 'instructor',
    locale,
    ...(filters?.location && {
      'fields.location.sys.contentType.sys.id': 'location',
      'fields.location.fields.slug[match]': filters.location,
    }),
    select: select?.join(','),
  });
};

export const useGetContentfulInstructors = (locale: string) => {
  return useQuery({
    queryKey: ['contentful', 'instructors', locale],
    queryFn: () => fetchInstructors(locale),
    staleTime: Infinity, // Doesn't need to refresh
  });
};

export const fetchInstructor = async (
  locale: string,
  fields: { id?: string; slug?: string },
  preview?: boolean,
  select?: string[],
) => {
  const instructors = await contentfulClient(preview).getEntries<i.TypeInstructorFields>({
    content_type: 'instructor',
    locale,
    include: 2,
    limit: 1,
    ...(fields.id && {
      'fields.zingfitId[match]': fields.id,
    }),
    ...(fields.slug && {
      'fields.slug': fields.slug,
    }),
    select: select?.join(','),
  });

  return instructors.items?.[0] || null;
};

export const useGetContentfulInstructorThumbnail = (locale: string, zingfitId?: string) => {
  return useQuery({
    queryKey: ['contentful', 'instructorThumbnail', locale, zingfitId],
    queryFn: () =>
      fetchInstructor(locale, { id: zingfitId }, false, ['fields.thumbnail', 'fields.zingfitId']),
    enabled: Boolean(zingfitId),
    staleTime: Infinity, // Doesn't need to refresh
  });
};

export const fetchInstructorOverview = async (
  locale: string,
  filters: i.FetchInstructorOverviewFilter,
  preview?: boolean,
) => {
  const instructorOverviews = await contentfulClient(
    preview,
  ).getEntries<i.TypeInstructorsOverviewFields>({
    content_type: 'instructorsOverview',
    locale,
    ...(filters?.location
      ? {
          'fields.location.sys.contentType.sys.id': 'location',
          'fields.location.fields.slug[match]': filters.location,
        }
      : filters.id && {
          'sys.id[match]': filters.id,
        }),
    limit: 1,
    include: 2,
  });

  return instructorOverviews.items?.[0] || null;
};

export const fetchInstructorOverviewPages = async (locale: string) => {
  const instructorOverviews = await contentfulClient().getEntries<i.TypeInstructorsOverviewFields>({
    content_type: 'instructorsOverview',
    locale,
    include: 2,
    select: 'fields.location,sys',
  });

  return instructorOverviews.items || [];
};

export const useGetRelatedInstructors = (slug: string) => {
  return useQuery({
    queryKey: ['contentful', 'instructors', slug, 'related-instructors'],
    queryFn: () => fetchInstructor('en', { slug }, false, ['fields.relatedInstructors']),
    staleTime: Infinity, // Doesn't need to refresh
    select: (instructorData) => instructorData.fields.relatedInstructors || [],
  });
};
