import * as i from 'types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'services/api';
import { getErrorMessage } from 'services/api/errorMessages';
import { sendError } from 'services/sentry';

const getPayment = async (orderId?: string) => {
  return api
    .get<i.PaymentInfo>({
      path: `/payments/${orderId}/paymentinfo`,
      type: 'zingfit',
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error getting payment info',
        method: 'getPayment',
        endpoint: `/payments/${orderId}/paymentinfo`,
        error,
        params: {
          orderId,
        },
      });
    });
};

export const useGetPayment = (orderId?: string) => {
  return useQuery<i.PaymentInfo | null, i.ZingfitApiError>({
    queryKey: ['payments', orderId],
    queryFn: () => getPayment(orderId),
    enabled: Boolean(orderId),
  });
};

export const verifyPaymentIntent = async (orderId: string, paymentIntentId: string) => {
  return new Promise<i.PaymentFulfillmentObject>((resolve, reject) => {
    const body = {
      saveCard: true,
      paymentIntentId,
    };

    api
      .post<i.PaymentFulfillmentObject>({
        path: `/payments/${orderId}/paymentintent`,
        type: 'zingfit',
        body,
      })
      .then((response) => resolve(response))
      .catch((error: i.ZingfitApiError) => {
        const message = getErrorMessage(error);

        sendError({
          type: 'api',
          message: 'Error verifying payment intent',
          method: 'verifyPaymentIntent',
          endpoint: `/payments/${orderId}/paymentintent`,
          error,
          params: {
            orderId,
            paymentIntentId,
            body,
          },
        });

        reject(message);
      });
  });
};

export const useVerifyPaymentIntent = () => {
  return useMutation<
    i.PaymentFulfillmentObject | null,
    i.ZingfitApiError,
    i.MutateVerifyPaymentPayload
  >({
    mutationFn: ({ orderId, paymentIntentId }) => verifyPaymentIntent(orderId, paymentIntentId),
  });
};

const createCreditCardPayment = async (payment: i.MutatePaymentPayload) => {
  return new Promise<i.PaymentFulfillmentObject>((resolve, reject) => {
    const { orderId, cardInfo, userData } = payment;

    const body = {
      firstName: cardInfo.firstName,
      lastName: cardInfo.lastName,
      address: userData.address,
      zip: userData.zip,
      token: cardInfo.token,
      saveCard: true,
    };

    api
      .post<i.PaymentFulfillmentObject>({
        path: `/payments/${orderId}/creditcard`,
        type: 'zingfit',
        body,
      })
      .then((response) => resolve(response))
      .catch((error: i.ZingfitApiError) => {
        const message = getErrorMessage(error);

        sendError({
          type: 'api',
          message: 'Error creating credit card payment',
          method: 'createCreditCardPayment',
          endpoint: `/payments/${orderId}/creditcard`,
          error,
          params: {
            payment,
            body,
          },
        });

        reject(message);
      });
  });
};

export const useCompleteCreditCardPayment = () => {
  return useMutation<i.PaymentFulfillmentObject | null, i.ZingfitApiError, i.MutatePaymentPayload>({
    mutationFn: (payment) => createCreditCardPayment(payment),
  });
};

const createPayment = async (payment: i.MutatePaymentPayload) => {
  return new Promise<i.PaymentOrder>((resolve, reject) => {
    const { orderId, cardInfo, userData } = payment;
    const body =
      cardInfo.paymentMethod === 'sepa'
        ? { sourceId: cardInfo.token }
        : {
            firstName: cardInfo.firstName,
            lastName: cardInfo.lastName,
            address: userData.address,
            zip: userData.zip,
            token: cardInfo.token,
          };
    api
      .post<i.PaymentOrder | i.PaymentFulfillmentObject>({
        path: `/payments/${orderId}/${cardInfo.paymentMethod}`,
        type: 'zingfit',
        body,
      })
      .then((orderOrFulfillmentObject) => {
        // It is possible the response is not an order but a payment fulfillment
        // object. If the order comes back as a payment method fulfillment object,
        // the order needs to be refetched and check that again.
        if (orderOrFulfillmentObject.hasOwnProperty('success')) {
          const fulfillmentObject = orderOrFulfillmentObject as i.PaymentFulfillmentObject;

          // If the order is successful, the refetched order will contain all the
          // info that meets the `isOrderSuccessful` requirements
          if (fulfillmentObject.success) {
            api
              .get<i.PaymentOrder>({
                path: `/orders/${orderId}`,
                type: 'zingfit',
              })
              .then((order) => {
                resolve(order);
              });
          } else {
            // If the order wasn't successful, the order cannot be validated because
            // the refetched order won't contain information about the failed payment.
            reject(fulfillmentObject.errorMessage);
          }
        } else {
          resolve(orderOrFulfillmentObject as i.PaymentOrder);
        }
      })
      .catch((error: i.ZingfitApiError) => {
        const message = getErrorMessage(error);

        sendError({
          type: 'api',
          message: 'Error creating payment',
          method: 'createPayment',
          endpoint: `/payments/${orderId}/${cardInfo.paymentMethod}`,
          error,
          params: {
            payment,
            body,
          },
        });

        reject(message);
      });
  });
};
export const useCompletePayment = () => {
  const queryClient = useQueryClient();

  return useMutation<i.PaymentOrder, string, i.MutatePaymentPayload>({
    mutationFn: (payment) => createPayment(payment),
    onSuccess: (payment) => {
      queryClient.invalidateQueries(['order', payment.id]);
    },
  });
};
