import * as i from 'types';

const formatClassSpot = (classData: i.MyClass) => {
  return {
    attendanceId: classData.attendanceId,
    label: Array.isArray(classData.spotLabel) ? classData.spotLabel[0] : classData.spotLabel,
    status: classData.status,
  };
};

// Get all unique classes user is sign up too. If a user has more spots in a class, return all spots as array.
const uniqueClasses = (myClasses: i.MyClass[]) =>
  myClasses.reduce((obj: { [key: string]: i.MyClass }, item: i.MyClass) => {
    if (!obj[item.classId]) {
      obj[item.classId] = { ...item };
    }

    if (
      obj[item.classId].spots &&
      !obj[item.classId].spots?.find((spot) => spot.attendanceId === item.attendanceId)
    ) {
      obj[item.classId].spots.push(formatClassSpot(item));
    } else {
      obj[item.classId].spots = [formatClassSpot(item)];
    }

    // Only show spots in the UI that are not cancelled. This way we keep the UI clean.
    obj[item.classId].spotLabel = obj[item.classId].spots
      .filter((spot) => spot.status && !['Cancelled', 'No Show'].includes(spot.status))
      .map((spot) => (spot.status === 'Waitlisted' ? 'waitlist' : spot.label));

    return obj;
  }, {});

export const getUniqueClasses = (myClasses?: i.MyClass[]) => {
  return Object.values(uniqueClasses(myClasses || []));
};
