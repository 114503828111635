import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useCookies, useRouter } from 'hooks';
import { useGetContentfulLocations } from 'queries/contentful/locations';
import { useGeolocation } from 'queries/geolocation';
import { isServer } from 'services/isServer';
import { getClosestLocation } from 'services/location';

export const CurrentLocationContext = React.createContext<CurrentLocationContextProps | null>(null);

export const CurrentLocationProvider = ({ children }: CurrentLocationProviderProps) => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const locations = useGetContentfulLocations(i18n.language);
  const geolocation = useGeolocation();
  const { cookies, setCookie, isCookiesAccepted } = useCookies();

  const [currentLocation, setCurrentLocation] = React.useState(cookies.location);
  const onSetLocation = (location: i.TypeLocation) => {
    setCurrentLocation(location.fields);

    if (isCookiesAccepted) {
      setCookie('location', location.fields, { path: '/' });
    }
  };

  const onSetDefaultLocation = () => {
    // Don't set default location if there is already a location set.
    if (!locations.data || currentLocation) return;

    const defaultLocation =
      locations.data.items.find((location) => location.fields.defaultLocation) ||
      locations.data.items[0];

    onSetLocation(defaultLocation);
  };

  const setClosestLocationToCity = (city: string) => {
    if (!locations.isSuccess) return;
    const location = locations.data.items.find((location) => location.fields.slug === city);

    if (location) {
      onSetLocation(location);

      // If this is a dynamic route with [location] in it, we want to navigate to the new location
      if (router.query.location) {
        router.push({
          query: {
            location: location.fields.slug,
          },
        });
      }
    }
  };

  React.useEffect(() => {
    if (isServer) return;

    // Switch to DE if the user is in Germany
    switch (geolocation.data?.geoplugin_countryCode) {
      case 'DE':
        if (i18n.language !== 'de') i18n.changeLanguage('de');
        break;
      default:
        if (i18n.language !== 'en') i18n.changeLanguage('en');
    }
  }, [geolocation.data?.geoplugin_countryCode]);

  React.useEffect(() => {
    // Wait until we have all locations fetched
    if (!locations.data) return;

    // If the user has a cookie, we don't want to change the location on page load
    if (cookies.location) return;

    if (!geolocation.data) return onSetDefaultLocation();

    // Get the closest studio location to the user
    getClosestLocation({
      latitude: geolocation.data.geoplugin_latitude,
      longitude: geolocation.data.geoplugin_longitude,
    })
      .then((closestLocation) => {
        // Closes location can be empty. In that case
        if (closestLocation) {
          setClosestLocationToCity(closestLocation.fields.slug);
        } else {
          onSetDefaultLocation();
        }
      })
      .catch((err) => {
        if (!!__PROD__) console.error(err);
        onSetDefaultLocation();
      });
  }, [cookies.location, locations.data, geolocation.data]);

  return (
    <CurrentLocationContext.Provider
      value={{
        currentLocation,
        setClosestLocationToCity,
      }}
    >
      {children}
    </CurrentLocationContext.Provider>
  );
};

type CurrentLocationContextProps = {
  currentLocation?: i.TypeLocationFields;
  setClosestLocationToCity: (city: string) => void;
};

export type CurrentLocationProviderProps = {
  children: React.ReactNode;
};
