import * as React from 'react';

import { CurrentLocationContext } from 'services/context/currentLocationContext';

export const useCurrentLocation = () => {
  const context = React.useContext(CurrentLocationContext);

  if (!context) {
    throw new Error('Components should be rendered inside the CurrentLocationContext.Provider component');
  }

  return context;
};
