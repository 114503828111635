import * as i from 'types';
import { useSession } from 'next-auth/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'services/api';
import { sendError } from 'services/sentry';

const getOrder = async (orderId?: string) => {
  return api
    .get<i.PaymentOrder>({
      path: `/orders/${orderId}`,
      type: 'zingfit',
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error getting order',
        method: 'getOrder',
        endpoint: `/orders/${orderId}`,
        error,
        params: {
          orderId,
        },
      });
    });
};

const createOrder = async (seriesId: string) => {
  const body = {
    seriesId,
    confirmTerms: true,
  };

  return api
    .post<i.PaymentOrder>({
      path: '/orders/series',
      type: 'zingfit',
      body,
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error creating order',
        method: 'createOrder',
        endpoint: '/orders/series',
        error,
        params: {
          body,
        },
      });
    });
};

const applyPromotionToOrder = (payload: i.ApplyOrderPromotionPayload) => {
  const body = {
    promoCode: payload.promoCode,
  };

  return new Promise<i.PaymentOrder>((resolve, reject) => {
    api
      .post<i.PromotionResponse>({
        path: `/payments/${payload.orderId}/promotion`,
        type: 'zingfit',
        body,
      })
      .then((response) => {
        if (response.success) {
          resolve(response.order);
        } else {
          // Promotion code could not be applied.
          // Could be because the series the user is trying to buy is not included in the promotion.
          reject({
            error: 'PROMOTION_APPLIED_DENIED',
            message: 'Could not apply promotion',
          });
        }
      })
      .catch(async (error: i.ZingfitApiError) => {
        sendError({
          type: 'api',
          message: 'Error applying promotion to order',
          method: 'applyPromotionToOrder',
          endpoint: `/payments/${payload.orderId}/promotion`,
          error,
          params: {
            payload,
            body,
          },
        });

        const errorResponse: i.ZingfitApiError = await error.json();
        if (errorResponse.data?.code === 109) {
          const body = {
            promoCode: payload.promoCode,
          };

          api
            .post({
              path: '/promotions/freeseries',
              type: 'zingfit',
              body,
            })
            .then(() => {
              // Re-fetch the order ourselves.
              // After applying the freeseries we don't get an order back from zingfit.
              getOrder(payload.orderId)
                .then((response) => {
                  if (response) {
                    resolve(response);
                  } else {
                    reject();
                  }
                })
                .catch((error) => reject(error));
            })
            .catch((error: i.ZingfitApiError) => {
              sendError({
                type: 'api',
                message: 'Error applying promotion to order',
                method: 'applyPromotionToOrder',
                endpoint: '/promotions/freeseries',
                error,
                params: {
                  payload,
                  body,
                },
              });

              reject(error);
            });
        } else {
          reject(error);
        }
      });
  });
};

const applyGiftCardToOrder = async (payload: i.ApplyOrderGiftCardPayload) => {
  return new Promise<i.PaymentOrder>((resolve, reject) => {
    const body = {
      code: payload.giftCardCode,
      pin: payload.giftCardPin,
    };

    api
      .post<i.PaymentOrder>({
        path: `/payments/${payload.orderId}/giftcard`,
        type: 'zingfit',
        body,
      })
      .then((order) => {
        resolve(order);
      })
      .catch((error) => {
        sendError({
          type: 'api',
          message: 'Error applying gift card to order',
          method: 'applyGiftCardToOrder',
          endpoint: `/payments/${payload.orderId}/giftcard`,
          error,
          params: {
            payload,
            body,
          },
        });

        reject(error);
      });
  });
};

const applyStoreCreditsToOrder = async (orderId: string) => {
  return api
    .post<i.PaymentOrder>({
      path: `/payments/${orderId}/storecredits`,
      type: 'zingfit',
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error applying store credits to order',
        method: 'applyStoreCreditsToOrder',
        endpoint: `/payments/${orderId}/storecredits`,
        error,
        params: {
          orderId,
        },
      });
    });
};

export const useGetOrder = (orderId?: string) => {
  const session = useSession();

  return useQuery<i.PaymentOrder | null, i.ZingfitApiError>({
    queryKey: ['order', orderId],
    queryFn: () => getOrder(orderId),
    enabled: Boolean(orderId) && session.status === 'authenticated',
  });
};

export const useCreateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<i.PaymentOrder | null, i.ZingfitApiError, string>({
    mutationFn: (seriesId) => createOrder(seriesId),
    onSuccess: (order) => {
      if (order) {
        queryClient.setQueryData<i.PaymentOrder>(['order', order.id], order);
      }
    },
  });
};

export const useApplyPromotionToOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<i.PaymentOrder, i.ZingfitApiError, i.ApplyOrderPromotionPayload>({
    mutationFn: (payload) => applyPromotionToOrder(payload),
    onSuccess: (order) => {
      queryClient.setQueryData<i.PaymentOrder>(['order', order.id], order);
    },
  });
};

export const useApplyGiftCardToOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<i.PaymentOrder | null, i.ZingfitApiError, i.ApplyOrderGiftCardPayload>({
    mutationFn: (payload) => applyGiftCardToOrder(payload),
    onSuccess: (order) => {
      if (order) {
        queryClient.setQueryData<i.PaymentOrder>(['order', order.id], order);
      }
    },
  });
};

export const useApplyStoreCreditsToOrder = () => {
  const queryClient = useQueryClient();

  return useMutation<i.PaymentOrder | null, i.ZingfitApiError, string>({
    mutationFn: (orderId) => applyStoreCreditsToOrder(orderId),
    onSuccess: (order) => {
      if (order) {
        queryClient.setQueryData<i.PaymentOrder>(['order', order.id], order);
      }
    },
  });
};
