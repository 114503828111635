import * as i from 'types';
import { useMutation } from '@tanstack/react-query';

import api from 'services/api';
import { sendError } from 'services/sentry';

import { bookClass } from './bookClass';

export const useBookClass = () => {
  return useMutation<void, i.ZingfitApiError, i.BookClassPayload>({
    mutationFn: (payload) => bookClass(payload),
  });
};

export const useBookWaitlist = () => {
  return useMutation<void, i.ZingfitApiError, i.BookWaitlistPayload>({
    mutationFn: (payload) => {
      return api
        .put({
          path: `/classes/${payload.classId}/waitlist`,
          type: 'zingfit',
          body: {
            // selectedSeries will always contain at least 1 credit
            seriesItemId: payload.selectedSeries?.[0]?.id,
          },
        })
        .catch((error) => {
          sendError({
            type: 'api',
            message: 'Error booking waitlist',
            endpoint: `/classes/${payload.classId}/waitlist`,
            method: 'useBookWaitlist',
            error,
            params: {
              payload,
              seriesItemId: payload.selectedSeries?.[0]?.id,
            },
          });
        });
    },
  });
};
