import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { useBodyScrollLock } from 'services/hooks';

import { BlurBackgroundContainer, Container } from './styled';

export const Sidebar: React.FC<SidebarProps> = ({
  isVisible,
  toggleVisibility,
  filterOpen,
  step,
  children,
}) => {
  const { setScrollLock } = useBodyScrollLock();

  React.useEffect(() => {
    setScrollLock(isVisible);
  }, [isVisible]);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [disableScroll, setDisableScroll] = React.useState(false);

  React.useEffect(() => {
    if (filterOpen) {
      containerRef.current?.scrollTo(0, 0);
      setDisableScroll(true);
    } else if (!filterOpen) {
      setTimeout(() => {
        setDisableScroll(false);
      }, 500);
    }
  }, [filterOpen]);

  React.useEffect(() => {
    if (step) {
      containerRef.current?.scrollTo(0, 0);
    }
  }, [step]);

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <BlurBackgroundContainer
            key="background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => toggleVisibility()}
          />
          <Container
            ref={containerRef}
            key="content"
            initial={{ opacity: 0, x: '100%' }}
            // The 0.999999 is a little hack to fix the video artifact appearing
            // when we blur with the BlurBackgroundContainer.
            animate={{ opacity: 0.999999, x: '0' }}
            exit={{ opacity: 0, x: '100%' }}
            transition={{ type: 'spring', duration: 0.2, bounce: 0 }}
            $disableScroll={disableScroll}
          >
            {children}
          </Container>
        </>
      )}
    </AnimatePresence>
  );
};

type SidebarProps = {
  isVisible: boolean;
  toggleVisibility: () => void;
  filterOpen?: boolean;
  step?: string;
  children: React.ReactNode;
};
