import * as i from 'types';

export const rewardsTestData: i.Reward[] = [
  {
    currentValue: 25,
    startDate: '2016-03-31T00:00:00',
    rewardDate: '2019-04-11T18:30:29.186',
    name: '25 rides',
    activationMode: 'startdate',
    triggerMode: 'attendance',
    triggerAmount: 25,
    rewardMode: 'none',
    reward: '0',
  },
  {
    currentValue: 50,
    startDate: '2016-03-31T00:00:00',
    rewardDate: '2019-07-04T07:30:27.059',
    name: '50 rides',
    activationMode: 'startdate',
    triggerMode: 'attendance',
    triggerAmount: 50,
    rewardMode: 'none',
    reward: '0',
  },
  {
    currentValue: 100,
    startDate: '2016-03-31T00:00:00',
    rewardDate: '2020-02-26T17:31:59.183',
    name: '100 rides',
    activationMode: 'startdate',
    triggerMode: 'attendance',
    triggerAmount: 100,
    rewardMode: 'none',
    reward: '0',
  },
];

export const activeRewardsTestData: i.Reward[] = [
  {
    currentValue: 123.0,
    startDate: '2016-03-31T07:00:00',
    name: '200 rides',
    activationMode: 'startdate',
    triggerMode: 'attendance',
    triggerAmount: 200,
    rewardMode: 'none',
    reward: '0',
  },
  {
    currentValue: 123.0,
    startDate: '2016-03-31T07:00:00',
    name: '500 rides',
    activationMode: 'startdate',
    triggerMode: 'attendance',
    triggerAmount: 500,
    rewardMode: 'series',
    reward: '1104989619939705873',
  },
  {
    currentValue: 123.0,
    name: '300 rides',
    activationMode: 'alltime',
    triggerMode: 'attendance',
    triggerAmount: 300,
    rewardMode: 'series',
    reward: '1104989619939705873',
  },
];
