import * as React from 'react';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ErrorBoundary } from '@sentry/react';
import { type QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from 'styled-components';

import { BookingProvider } from 'services/context/bookingContext';
import { CurrentLocationProvider } from 'services/context/currentLocationContext';
import { LocationSidebarProvider } from 'services/context/locationSidebarContext';
import { PageBackgroundProvider } from 'services/context/pageBackgroundContext';
import { handleErrorBoundaryCapture } from 'services/sentry';
import theme from 'styles/theme';

export const Providers: React.FC<Props> = ({ children, queryClient, session }) => {
  return (
    <ErrorBoundary beforeCapture={handleErrorBoundaryCapture}>
      <ThemeProvider theme={theme}>
        <SessionProvider session={session}>
          <QueryClientProvider client={queryClient}>
            <CurrentLocationProvider>
              <BookingProvider>
                <LocationSidebarProvider>
                  <CookiesProvider>
                    <PageBackgroundProvider>{children}</PageBackgroundProvider>
                  </CookiesProvider>
                </LocationSidebarProvider>
              </BookingProvider>
            </CurrentLocationProvider>
            <ReactQueryDevtools position="bottom-right" />
          </QueryClientProvider>
        </SessionProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export type Props = {
  children: React.ReactNode;
  session: Session;
  queryClient: QueryClient;
};
