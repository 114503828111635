import * as i from 'types';
import { createClient, Asset, CreateClientParams } from 'contentful';
import invariant from 'tiny-invariant';

invariant(
  process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  'Missing ENV variable: NEXT_PUBLIC_CONTENTFUL_SPACE_ID',
);
invariant(
  process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  'Missing ENV variable: NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN',
);
invariant(
  process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  'Missing ENV variable: NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT',
);

const previewConfig: CreateClientParams = {
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN || '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  host: 'preview.contentful.com',
  removeUnresolved: true,
};

const normalConfig: CreateClientParams = {
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  removeUnresolved: true,
};

export const contentfulClient = (preview?: boolean) => {
  const config = preview ? previewConfig : normalConfig;
  return createClient(config);
};

export const getFocalPointImage = (asset?: i.TypeImageWithFocalPointFields, sizes?: string) => {
  const image = asset?.image?.fields;
  const srcSet = getImageSrcSet(image, sizes);

  if (!asset || !srcSet || !image?.file?.details?.image || !asset.focalPoint.focalPoint) {
    return;
  }

  const url = image.file.url;
  const { width, height } = image.file.details.image;
  const { x, y } = asset.focalPoint.focalPoint;

  let xPercentage = (x / width) * 100;
  if (xPercentage < 0) {
    xPercentage = 0;
  }

  let yPercentage = (y / height) * 100;
  if (yPercentage < 0) {
    yPercentage = 0;
  }

  return {
    position: `${xPercentage}% ${yPercentage}%`,
    src: `${url}?w=${width}`,
    srcSet,
    sizes,
    alt: image.title || '',
  };
};

export const getImageSrcSet = (image?: Asset['fields'], sizes?: string) => {
  if (!image?.file) return undefined;

  const url = image.file.url;

  if (sizes) {
    const sizesList = sizes.split(',');

    return sizesList
      .map((size) => {
        // Determine the index of the size string
        // e.g. "(max-width: 100px) 200px" will be split to [(max-width: 100px), 200px] so we need
        // index 1
        // if the string does not contain "max-width" then we need index 0
        const idx = size.includes('max-width') ? 1 : 0;
        // Split "(max-width: 100px) 200px" to [(max-width: 100px), 200px] and then to [200]
        const w = size.trim().split(') ')[idx].split('px')[0];

        return `${url}?w=${w} ${w}w`;
      })
      .join(', ');
  }

  return `${url}?w=400 400w, ${url}?w=768 768w, ${url}?w=992 992w, ${url}?w=1200 1200w, ${url}?w=1920 1920w`;
};
