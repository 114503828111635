import * as React from 'react';

import { isServer } from 'services';

import { useRouter } from './useRouter';

export const useZingfitRedirects = () => {
  const router = useRouter();

  // Check Zingfit redirect from reset password email
  React.useEffect(() => {
    const [path, hash] = router.asPath.split('#');

    // Only check for redirects on the client homepage (we're checking hash routes)
    if (!isServer && path === '/' && hash) {
      if (hash.startsWith('/resetpassword/token/')) {
        router.replace(`/app/reset-password?token=${hash.slice(21)}`);
      }

      if (hash.startsWith('/newaccount/r/')) {
        router.replace(`/app/register?r=${hash.slice(14)}`);
      }
    }
  }, [isServer, router.asPath]);
};
