import * as i from 'types';
import * as React from 'react';

import { TWO_PI } from 'services/mathHelpers';
import { getColor } from 'styles/utils';

import { ProgressRingContainer } from './styled';

export const ProgressRing: React.FC<ProgressRingProps> = ({
  stroke,
  progress,
  size = 60,
  color = 'white',
}) => {
  const halfSize = size / 2;
  const r = halfSize - 2 * stroke;
  const circumference = r * TWO_PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <ProgressRingContainer>
      <svg viewBox={`0 0 ${size} ${size}`}>
        <circle
          stroke={getColor(color)}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset, transition: '0.15s stroke-dashoffset' }}
          r={r}
          cx={halfSize}
          cy={halfSize}
        />
      </svg>
    </ProgressRingContainer>
  );
};

type ProgressRingProps = {
  stroke: number;
  progress: number;
  size?: number;
  color?:
    | i.ColorsFromTheme<'white' | 'brown'>
    | i.SubcolorFromTheme<'green', 'dark'>
    | i.SubcolorFromTheme<'blue', 'dark'>;
};
