import * as i from 'types';

import api from 'services/api';
import { sendError } from 'services/sentry';

export const fetchClassAttendance = (classId?: string, studioId?: string) =>
  new Promise<i.Attendance>(async (resolve, reject) => {
    const studioAttendances = await api
      .get<i.Attendance[]>({
        path: `/sites/${studioId}/classes/attendance`,
        type: 'zingfit',
      })
      .catch((error) => {
        sendError({
          type: 'api',
          message: 'Error booking class',
          endpoint: `/sites/${studioId}/classes/attendance`,
          method: 'fetchClassAttendance',
          error,
          params: {
            studioId,
            classId,
          },
        });
      });

    const classAttendance = studioAttendances?.find((attendance) => attendance.classId === classId);

    if (classAttendance) {
      resolve(classAttendance);
    } else {
      reject();
    }
  });
