import * as i from 'types';
import * as React from 'react';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';

import { useBookClass, useBookWaitlist } from 'queries/booking';
import { useGetClassAttendance, useGetScheduleClasses } from 'queries/classes';
import { useGetClassSeries } from 'queries/series';
import { getScheduleClassStatus } from 'services/class';
import { filterScheduleClasses } from 'services/filterScheduleUtils';
import { useBookingFilters, useCurrentLocation } from 'services/hooks';

export const BookingContext = React.createContext<BookingContextProps | null>(null);

export const BookingProvider: React.FC<BookingProviderProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const session = useSession();
  const router = useRouter();
  const [bookingSidebarVisible, setBookingSidebarVisible] = React.useState(false);
  const [step, setStep] = React.useState<i.BookingSidebarSteps>('schedule');
  const [selectedSpots, setSelectedSpots] = React.useState<i.Spot[]>([]);

  const { currentLocation } = useCurrentLocation();
  const scheduleClasses = useGetScheduleClasses(currentLocation, bookingSidebarVisible);
  const {
    filterOpen,
    setFilterOpen,
    activeFilters,
    setActiveFilters,
    resetActiveFilters,
    activeDate,
    setActiveDate,
    resetActiveDate,
  } = useBookingFilters();

  const filteredClasses = filterScheduleClasses(
    scheduleClasses.data || [],
    activeFilters,
    activeDate,
  );
  const bookClass = useBookClass();
  const bookWaitlist = useBookWaitlist();
  const [selectedClass, setSelectedClass] = React.useState<i.ExtendedClass | i.Class | undefined>(
    undefined,
  );
  const classSeries = useGetClassSeries(selectedClass?.id);
  const classAttendance = useGetClassAttendance(selectedClass?.id, selectedClass?.siteId);

  // If the booking sidebar gets closed, reset all states and properties to the
  // initial values
  React.useEffect(() => {
    if (!bookingSidebarVisible) {
      setStep('schedule');
      setSelectedClass(undefined);
      setSelectedSpots([]);
      setFilterOpen(false);
      resetActiveDate();
    }
  }, [bookingSidebarVisible]);

  // Delete all previously selected spots if we get to the map step again
  React.useEffect(() => {
    if (step === 'map') {
      setSelectedSpots([]);
    }
  }, [step]);

  const toggleBookingSidebar = () => {
    setBookingSidebarVisible(!bookingSidebarVisible);
  };

  const handleSelectClass = async (scheduleClass: i.ExtendedClass | i.Class) => {
    // If the user is not yet logged in, redirect to the login page
    if (session.status !== 'authenticated') {
      setBookingSidebarVisible(false);

      router.push({
        pathname: '/app/login',
        query: {
          callbackUrl: router.asPath,
        },
      });
    }

    // Set the selected class. The useEffect under here will trigger, and will determine what the
    // next step should be (map or waitlist).
    setSelectedClass(scheduleClass);
  };

  React.useEffect(() => {
    if (!classSeries.data || !classAttendance.data || !selectedClass || step !== 'schedule') return;
    // Now we can check the user's series that can be used to book this class
    let extendedClass: i.ExtendedClass | undefined;

    // Check if we got an i.ExtendedClass object. If so, we can get its
    // status based on its additional properties. If not, we need to fetch
    // this extension from Zingfit
    if (!selectedClass.hasOwnProperty('spotCount')) {
      extendedClass = { ...selectedClass, ...classAttendance.data } as i.ExtendedClass;
    } else {
      extendedClass = selectedClass as i.ExtendedClass;
    }

    const status = getScheduleClassStatus(extendedClass);

    if (status === 'waitlist') {
      if (window.confirm(t('Are you sure you want to sign up for the waitlist?') as string)) {
        bookWaitlist.mutate(
          {
            classId: selectedClass.id,
            selectedSeries: [classSeries.data.series[0]],
          },
          {
            onSuccess: () => {
              setStep('successWaitlist');
            },
          },
        );
      }
    } else {
      setStep('map');
    }
  }, [classSeries.data, classAttendance.data]);

  const handleSelectSpot = (newSpot: i.Spot) => {
    let newlySelectedSpots: i.Spot[] = selectedSpots;

    if (selectedSpots.some((spot) => spot.id === newSpot.id)) {
      newlySelectedSpots = newlySelectedSpots.filter((spot) => spot.id !== newSpot.id);
    } else {
      newlySelectedSpots.push(newSpot);
    }

    setSelectedSpots([...newlySelectedSpots]);
  };

  const handleGoToSelectSeries = async () => {
    if (!classSeries.data) return;

    // If there are no credits available for the selected class, user gets
    // redirected to buy a series
    if (classSeries.data.series.length === 0) {
      try {
        await handleBooking();
      } catch (error) {
        if (classSeries.data.availableCredits < selectedSpots.length) {
          if (
            window.confirm(
              t(
                'There are no credits available to book the selected class, do you want to go to the pricing page?',
              ) as string,
            )
          ) {
            router.push(`/pricing/${currentLocation?.slug || ''}`);
          }
        }
      }
    } else {
      setStep('selectSeries');
    }
  };

  const handleBooking = async (selectedSeries: i.UserSeries[] = []) => {
    if (!selectedClass?.id) return;

    await bookClass.mutateAsync(
      {
        classId: selectedClass?.id,
        spotIds: selectedSpots.map((spot) => spot.id),
        selectedSeries,
      },
      {
        onSuccess: () => {
          handleSuccessfulBooking();
        },
      },
    );
  };

  const handleSuccessfulBooking = () => {
    setStep('successBooking');

    // Invalidate all user data after handling the booking.
    queryClient.invalidateQueries(['me']);
  };

  const handleGoBack = () => {
    switch (step) {
      case 'map':
        setStep('schedule');
        break;
      case 'selectSeries':
        setStep('map');
        break;
    }
  };

  return (
    <BookingContext.Provider
      value={{
        bookingSidebarVisible,
        selectedClass,
        filteredClasses,
        step,
        filterOpen,
        setFilterOpen,
        backButtonVisible: !['schedule', 'successWaitlist', 'successBooking'].includes(step),
        selectedSpots,
        setBookingSidebarVisible,
        setSelectedClass,
        setStep,
        handleSelectClass,
        handleSelectSpot,
        handleGoToSelectSeries,
        handleSuccessfulBooking,
        handleGoBack,
        toggleBookingSidebar,
        handleBooking,
        activeFilters,
        setActiveFilters,
        resetActiveFilters,
        activeDate,
        setActiveDate,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

type BookingContextProps = {
  bookingSidebarVisible: boolean;
  selectedClass?: i.ExtendedClass | i.Class;
  filteredClasses?: i.ExtendedClass[];
  step: i.BookingSidebarSteps;
  filterOpen: boolean;
  backButtonVisible: boolean;
  selectedSpots: i.Spot[];
  setSelectedClass: (selectedClass: i.ExtendedClass | i.Class) => void;
  setBookingSidebarVisible: (bookingSidebarVisible: boolean) => void;
  setStep: (step: i.BookingSidebarSteps) => void;
  setFilterOpen: (filterOpen: boolean) => void;
  handleSelectClass: (ride: i.ExtendedClass | i.Class) => void;
  handleSelectSpot: (spot: i.Spot) => void;
  handleGoToSelectSeries: () => void;
  handleSuccessfulBooking: () => void;
  handleGoBack: () => void;
  toggleBookingSidebar: () => void;
  handleBooking: (series: i.UserSeries[]) => void;
  activeFilters: i.ClassesFilters;
  setActiveFilters: (filters: i.ClassesFilters) => void;
  resetActiveFilters: () => void;
  activeDate: string;
  setActiveDate: (date: string) => void;
};

export type BookingProviderProps = {
  children: React.ReactNode;
};
