import { HTMLMotionProps } from 'framer-motion';

import { usePageBackground } from 'hooks/usePageBackground';
import theme from 'styles/theme';

export const useWithComponentAnimation = (options: Options) => {
  const { changeBackgroundColor } = usePageBackground();

  const slideUpAnimationProps: HTMLMotionProps<'img'> = {
    initial: { y: options.image.initialY },
    whileInView: { y: 0 },
    transition: { duration: options.image.duration },
  };

  const onViewportEnter = () => {
    if (options.background?.enabled) {
      changeBackgroundColor(theme.colors.darkRed);
    }
  };

  const onViewportLeave = () => {
    if (options.background?.enabled) {
      changeBackgroundColor(theme.colors.white);
    }
  };

  return {
    imageAnimation: {
      enabled: options.image.enabled,
      props: slideUpAnimationProps,
    },
    backgroundAnimation: {
      enabled: options.background?.enabled,
      onViewportEnter,
      onViewportLeave,
    },
  };
};

type Options = {
  background?: {
    /** Determines if the animation should play */
    enabled: boolean;
  };
  image: {
    /** Determines if the animation should play */
    enabled: boolean;
    /** The Y offset in pixels */
    initialY: number;
    /** In seconds */
    duration: number;
  };
};
