import React from 'react';

export const useInterval = (callback: (intervalId: NodeJS.Timer) => void, delay: number) => {
  const savedCallback = React.useRef<(intervalId: NodeJS.Timer) => void>();
  const intervalId = React.useRef<NodeJS.Timer>();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const tick = () => {
      if (savedCallback && savedCallback.current && intervalId.current) {
        savedCallback.current(intervalId.current);
      }
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      intervalId.current = id;
      return () => clearInterval(id);
    }
  }, [delay]);

  return intervalId;
};
