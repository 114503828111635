import * as i from 'types';

import api from 'services/api';
import { sendError } from 'services/sentry';

export const fetchScheduleClasses = async (location?: i.TypeLocationFields) => {
  const allStudios = await api
    .get<i.SitesResponse>({
      path: '/sites/selector',
      withAuth: false,
    })
    .catch((error: i.ZingfitApiError) => {
      sendError({
        type: 'api',
        message: 'Could not get studios data',
        method: 'fetchScheduleClasses',
        endpoint: '/sites/selector',
        error,
        params: {
          location,
        },
      });

      return {} as i.SitesResponse;
    });

  // Select only those studios that are from the preferred city
  const studiosByCity = Object.keys(allStudios)
    .map((key) => allStudios[key].cities)
    .reduce((acc, cur) => ({ ...acc, ...cur }), {} as i.CurrentStudio);
  const currentStudios = studiosByCity[location!.name] as i.CurrentStudio[];

  // We get all classes and all attendances for each required studios
  const classesPerStudio = await Promise.all(
    currentStudios?.map(
      (studio) =>
        new Promise<i.ExtendedClass[]>(async (resolve, reject) => {
          const [studioClasses, studioClassesAttendance] = await Promise.all([
            api
              .get<i.ExtendedClassList>({
                path: `/sites/${studio.id}/classes`,
                type: 'zingfit',
                withAuth: false,
              })
              .then(({ classes }) => classes)
              .catch((error) =>
                onStudioFetchError(error, studio, {
                  endpoint: `/sites/${studio.id}/classes`,
                  method: 'fetchScheduleClasses',
                }),
              ),
            api
              .get<i.Attendance[]>({
                path: `/sites/${studio.id}/classes/attendance`,
                type: 'zingfit',
                withAuth: false,
              })
              .catch((error) =>
                onStudioFetchError(error, studio, {
                  endpoint: `/sites/${studio.id}/classes/attendance`,
                  method: 'fetchScheduleClasses',
                }),
              ),
          ]);

          // We need all the data for showing the schedule, including the attendance
          // data. In some cases, for example on the instructor and studio pages the
          // attendance data is not fetched along with the schedule, so this part does
          if (studioClasses && studioClassesAttendance) {
            const extendedStudioClassesData: i.ExtendedClass[] = studioClasses.map(
              (studioClass) => {
                const relatedAttendance = studioClassesAttendance.find(
                  (attendance) => attendance.classId === studioClass.id,
                );

                return {
                  ...studioClass,
                  spotCount: relatedAttendance?.spotCount,
                  enrolled: relatedAttendance?.enrolled,
                  waitlisted: relatedAttendance?.waitlisted,
                };
              },
            );

            return resolve(extendedStudioClassesData);
          }

          return reject();
        }),
    ),
  ).catch((error, ...errors) => {
    sendError({
      error,
      type: 'api',
      message: error.message || error.error,
      method: 'getScheduleClasses',
      params: { errors },
    });
  });

  if (!classesPerStudio) {
    return [];
  }

  const classes = classesPerStudio.reduce((acc, cur) => acc.concat(cur), []);
  return classes;
};

const onStudioFetchError = (
  error: any,
  studio: i.CurrentStudio,
  callerData: Record<string, string>,
) => {
  if (__DEV__) {
    console.error('failed to fetch data for studio class attendance', studio.id, studio.name);
    console.error(error);
  }

  sendError({
    error,
    type: 'api',
    message: error.message || error.error,
    ...callerData,
    params: {
      error,
      studio,
    },
  });

  return null;
};
