import styled, { css, keyframes } from 'styled-components';

import { media } from 'styles/utils';

import { ButtonProps } from './types';

export const buttonLoaderAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

export const StyledButtonLoader = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 6px;
    height: 6px;
    margin-right: 3px;
    background-color: ${({ theme }) => theme.colors.gray};
    border-radius: 100%;
    animation: ${buttonLoaderAnimation} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`;

export const ButtonIcon = styled.div`
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.button<ButtonProps>`
  width: auto;
  min-width: 0;
  height: 48px;
  padding: 12px 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  font-size: 14px;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-image: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.colors.gray.medium}, ${theme.colors.blue}) 2`};
  color: ${({ theme }) => theme.colors.text};
  background-color: transparent;
  transition: border-image 0.3s ease;

  ${({ noArrow, $isLoading }) =>
    !noArrow &&
    !$isLoading &&
    css`
      &:after {
        font-family: ${({ theme }) => theme.fonts.favoritPro};
        display: inline-block;
        content: '->';
        margin-left: 8px;
      }
    `}

  svg {
    max-width: 24px;
    max-height: 24px;
  }

  ${media.desktop`
    min-width: 335px;
    + button {
      margin: 0 0 0 8px;
    }
  `}

  ${({ $iconOnly }) =>
    $iconOnly &&
    css`
      width: 48px;

      ${ButtonIcon} {
        margin: 0;
      }
    `}

  ${({ $iconOnlyOnMobile }) =>
    $iconOnlyOnMobile &&
    css`
      width: 48px;

      ${ButtonIcon} {
        margin: 0;
      }

      > span {
        display: none;
      }

      ${media.tablet`
      width: auto;

      > span {
        display: block;
      }
    `}
    `}

  ${({ $iconPosition }) =>
    $iconPosition === 'right' &&
    css<ButtonProps>`
      flex-direction: row-reverse;

      ${ButtonIcon} {
        margin: 0 0 0 8px;

        ${({ $iconOnlyOnMobile }) =>
          $iconOnlyOnMobile &&
          css`
            margin: 0;

            ${media.tablet`
          margin: 0 0 0 8px;
        `}
          `}
      }
    `}

  ${({ size }) =>
    size === 'auto' &&
    css`
      width: auto;
    `}

  ${({ size }) =>
    size === 'fullWidth' &&
    css`
      width: 100%;
    `}

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      cursor: not-allowed;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border: 2px solid ${({ theme }) => theme.colors.gray.medium};
      border-image: none;
      color: ${({ theme }) => theme.colors.gray.medium};

      ${media.desktop`
      transition: none;
    `}
    `};

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      border: none;
      opacity: 0.4;
    `}

  ${({ variant, theme }) =>
    variant === 'tertiary' &&
    css`
      border: none;
      background-color: ${theme.colors.white};
    `}

  ${({ variant, theme }) =>
    variant === 'quaternary' &&
    css`
      color: ${({ theme }) => theme.colors.red};
      border: 1px solid ${({ theme }) => theme.colors.red};
    `}
`;
