import * as i from 'types';

import { fetchStudios } from 'queries/contentful/studios';

import { getDistanceFromLatLonInKm } from './mathHelpers';

export async function getClosestLocation(position: i.PositionState) {
  const studios = await fetchStudios('en');

  let lowestKm: number | null = null;
  let closest: i.TypeStudio | undefined = undefined;

  for (const studio of studios.items) {
    const km = getDistanceFromLatLonInKm(
      Number(position.latitude),
      Number(position.longitude),
      Number(studio.fields.coordinates.lat),
      Number(studio.fields.coordinates.lon),
    );

    // Only set closest location if it's actually close (less than 1000 km)
    // The application will can default to another location in that case.
    if (km < 1000 && (lowestKm == null || km < lowestKm)) {
      closest = studio;
      lowestKm = km;
    }
  }

  return closest?.fields.location;
}
