import * as React from 'react';

import { LocationSidebarContext } from 'services/context/locationSidebarContext';

export const useLocationSidebar = () => {
  const context = React.useContext(LocationSidebarContext);

  if (!context) {
    throw new Error('Components should be rendered inside the LocationSidebarContext.Provider component');
  }

  return context;
};
