export default (storageFilters: Record<string, unknown>) => {
  return (
    'instructor' in storageFilters &&
    'studios' in storageFilters &&
    'time' in storageFilters &&
    'type' in storageFilters &&
    Array.isArray(storageFilters.instructor) &&
    Array.isArray(storageFilters.studios) &&
    Array.isArray(storageFilters.time) &&
    Array.isArray(storageFilters.type)
  );
};
