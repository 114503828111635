import * as React from 'react';

export const LocationSidebarContext = React.createContext<LocationSidebarContextProps | null>(null);

export const LocationSidebarProvider = ({ children }: LocationSidebarProviderProps) => {
  const [locationSidebarVisible, setLocationSidebarVisible] = React.useState(false);

  const toggleLocationSidebar = () => {
    setLocationSidebarVisible(!locationSidebarVisible);
  };

  return (
    <LocationSidebarContext.Provider
      value={{
        toggleLocationSidebar,
        locationSidebarVisible,
      }}
    >
      {children}
    </LocationSidebarContext.Provider>
  );
};

type LocationSidebarContextProps = {
  toggleLocationSidebar: () => void;
  locationSidebarVisible: boolean;
};

export type LocationSidebarProviderProps = {
  children: React.ReactNode;
};
