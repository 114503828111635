import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { useGetOrder } from 'queries/orders';
import {
  useCompleteCreditCardPayment,
  useCompletePayment,
  useVerifyPaymentIntent,
  useGetPayment,
} from 'queries/payments';
import { useGetMe } from 'queries/user';
import { useCurrentLocation } from 'services/hooks';
import { isServer } from 'services/isServer';
import { isOrderSuccessful, processPaymentResponse } from 'services/order';
import { get3DSecureAuthUrl } from 'services/payment';

const methods: i.PaymentMethodSelectItem[] = [
  {
    label: 'Credit card',
    value: 'creditcard',
    country: ['The Netherlands', 'Netherlands', 'Germany', 'Deutschland', 'Belgium', 'Belgien'],
  },
];

const methodsPackages: i.PaymentMethodSelectItem[] = [
  { label: 'iDEAL', value: 'ideal', country: ['The Netherlands', 'Netherlands'] },
  { label: 'Sofort', value: 'sofort', country: ['Germany', 'Deutschland'] },
];

const methodsMemberships: i.PaymentMethodSelectItem[] = [
  /* Disable SEPA until further notice, memberships will only have default creditcard */
  // {
  //   label: 'SEPA',
  //   value: 'sepa',
  //   country: ['The Netherlands', 'Netherlands', 'Germany', 'Deutschland'],
  // },
];

export const PaymentContext = React.createContext<null | PaymentState>(null);

export const initialState: PaymentState = {
  userData: undefined,
  order: undefined,
  paymentInfo: undefined,
  filteredPaymentMethods: [],
  selectedPaymentMethod: 'creditcard',
  setSelectedPaymentMethod: () => {},
  paymentProcessing: false,
};

export const PaymentContextProvider: React.FC<PaymentContextProviderProps> = ({
  children,
  orderId,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const params = (router?.query || {}) as PaymentPageParams;
  const { currentLocation } = useCurrentLocation();

  const user = useGetMe();
  const { data: order } = useGetOrder(orderId);
  // Retrieve payment information if not present yet
  const paymentInfo = useGetPayment(orderId);

  const verifyPaymentIntent = useVerifyPaymentIntent();
  const completeCreditCardPayment = useCompleteCreditCardPayment();
  const completePayment = useCompletePayment();

  const [paymentTried, setPaymentTried] = React.useState(false);
  const [paymentProcessing, setPaymentProcessing] = React.useState(false);

  // Payment methods are only available for certain countries. The selected
  // series is not connected to a country unfortunately, so we're allowing the
  // payment methods by the user's current location
  const filteredPaymentMethods = () => {
    let filteredOnCountry: i.PaymentMethodSelectItem[] = [];

    if (order && order.seriesOrderItems?.[0].contract) {
      filteredOnCountry = [...methodsMemberships, ...methods];
    } else {
      filteredOnCountry = [...methodsPackages, ...methods];
    }

    return filteredOnCountry.filter((method) => method.country.includes(currentLocation!.country));
  };

  // If SEPA is available, set it as default, otherwise fallback to creditcard
  const defaultPaymentMethod = (): i.PaymentMethod => {
    if (params.paymentMethod) return params.paymentMethod;
    if (filteredPaymentMethods().some((item) => item.value === 'sepa')) return 'sepa';

    return 'creditcard';
  };

  // Select default payment method to have at least one value
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<i.PaymentMethod>(
    defaultPaymentMethod(),
  );

  const handleCreditCardPayment = async () => {
    // For a credit card payment the following requirements need to meet
    // 1. The URL params has `paymentMethod` set to `creditcard` (or `card` to
    //    be backwards compatible)
    // 2. The address object is loaded (required parameter for completion)
    // 3. The order object is loaded (optional parameter for completion)
    // 4. The payment hasn't been tried already
    if (
      !params.token ||
      !params.paymentMethod ||
      !['card', 'creditcard'].includes(params?.paymentMethod) ||
      !user.data ||
      !order ||
      !paymentInfo.data ||
      paymentTried
    )
      return;

    // Set a loading state for the user
    setPaymentProcessing(true);

    // Set the boolean to true not to try again (until we hit the checkout button again)
    setPaymentTried(true);

    const cardInfo: i.CardInfo = {
      paymentMethod: 'creditcard',
      firstName: params.firstName || order.billingAddress.firstName,
      lastName: params.lastName || order.billingAddress.lastName,
      token: params.token,
    };

    let fulfillmentObject: i.PaymentFulfillmentObject | null = null;
    if (params.validatePaymentIntent) {
      fulfillmentObject = await verifyPaymentIntent.mutateAsync({
        orderId: order.id,
        paymentIntentId: params.token,
      });
    } else {
      fulfillmentObject = await completeCreditCardPayment.mutateAsync({
        orderId: order.id,
        userData: user.data,
        cardInfo,
      });
    }

    if (fulfillmentObject?.success) {
      router.push('/checkout/completed');
    } else {
      // The credit card payment flow can trigger 3DS authentication which
      // requires an extra step in order to complete payment
      if (fulfillmentObject?.requiresAction) {
        // Get all parameters for redirection
        const authUrl = get3DSecureAuthUrl({
          order: order,
          paymentInfo: paymentInfo.data,
          location: router,
          paymentIntentClientSecret: fulfillmentObject.clientSecret,
        });

        if (authUrl && !isServer) {
          window.location.href = authUrl;
        }
      } else {
        alert(t(fulfillmentObject?.errorMessage || ''));
        setPaymentProcessing(false);
      }
    }
  };

  const handleAsyncPayment = async () => {
    // Exclude credit card payment from this flow
    if (
      !params?.token ||
      !params?.paymentMethod ||
      ['card', 'creditcard'].includes(params?.paymentMethod) ||
      !user.data ||
      !order
    ) {
      return;
    }

    if (isOrderSuccessful(order)) {
      router.push('/checkout/completed');
      return;
    }

    // If the user already tried to complete the order, it updates the order
    // object again. We need to stop the frontend to continuously retry
    // completing it
    if (paymentTried) return;

    const paymentMethod = params?.paymentMethod as i.PaymentMethod;
    const hasToken = !!params?.token;

    if (hasToken && user.data) {
      const cardInfo: i.CardInfo = {
        paymentMethod: paymentMethod,
        firstName: params.firstName || order.billingAddress.firstName,
        lastName: params.lastName || order.billingAddress.lastName,
        token: params.token,
      };

      // Set a loading state for the user
      setPaymentProcessing(true);

      // Everything is ready to complete an order, we set a boolean to stop
      // automatic retrying of this process
      setPaymentTried(true);

      const newOrder = await completePayment.mutateAsync({
        orderId: order.id,
        userData: user.data,
        cardInfo,
      });

      processPaymentResponse(newOrder).catch(
        (errorMessage: string | { message: string; code: string }) => {
          if (typeof errorMessage === 'string') {
            alert(t(errorMessage));
          } else {
            alert(t(errorMessage.message || ''));
            if (errorMessage.code === 'restart_payment') {
              router.push('/pricing');
            }
          }

          setPaymentProcessing(false);
        },
      );
    }
  };

  const handlePaymentRedirect = async () => {
    if (order && paymentInfo.data && user.data) {
      // Don't try to handle the payments if there is an error in the url
      if (params?.['error[message]']) {
        alert(t(params['error[message]']));
      } else if (order?.orderStatus === 'Closed') {
        router.push('/checkout/completed');
      } else {
        await Promise.all([handleCreditCardPayment(), handleAsyncPayment()]);
      }

      // Always remove the query params afterwards
      const splitUrl = router.asPath.split('?')[0];
      router.replace(splitUrl, undefined, { shallow: true });
    }
  };

  React.useEffect(() => {
    handlePaymentRedirect();
  }, [order, paymentInfo.data, user.data]);

  return (
    <PaymentContext.Provider
      value={{
        order: order,
        giftCard: order?.orderItems?.[0].giftCard,
        paymentInfo: paymentInfo.data,
        userData: user.data,
        filteredPaymentMethods: filteredPaymentMethods(),
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        paymentProcessing,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

type PaymentState = {
  userData?: i.UserMe;
  order?: i.PaymentOrder | null;
  giftCard?: i.GiftCardOrder;
  paymentInfo?: i.PaymentInfo | null;
  filteredPaymentMethods: i.PaymentMethodSelectItem[];
  selectedPaymentMethod: i.PaymentMethod;
  setSelectedPaymentMethod: (paymentMethod: i.PaymentMethod) => void;
  paymentProcessing: boolean;
};

type PaymentContextProviderProps = {
  orderId: string;
  children: React.ReactNode;
};

type PaymentPageParams = Partial<i.CardInfo> & {
  error?: {
    message: string;
  };
  validatePaymentIntent?: boolean;
};
