import { createGlobalStyle } from 'styled-components';

const globalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.white};
    height: 100%;
    font-feature-settings: 'ss01' off, 'ss04' on;
  }

  main, #modal, #cookie-consent {
    font-family: ${({ theme }) => theme.fonts.favoritPro};
  }

  .mapboxgl-canvas-container .mapboxgl-marker {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.blue};
    transition: border-radius 0.3s;
    cursor: pointer;

    &:hover {
      border-radius: 0px;
    }

    svg {
      display: none;
    }
  }
`;

export default globalStyle;
