import * as i from 'types';
import * as React from 'react';
import type { Session } from 'next-auth';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic.js';
import localFont from 'next/font/local';
import Head from 'next/head';
import { type DehydratedState, Hydrate, QueryClient } from '@tanstack/react-query';

import { useRouteTracking } from 'hooks/useRouteTracking';
import { useZingfitRedirects } from 'hooks/useZingfitRedirects';
import GlobalStyle from 'styles';
import { Zendesk } from 'common/general/Zendesk';
import { BackgroundContainer } from 'common/layout/BackgroundContainer';
import { CookieBar } from 'common/layout/CookieBar';
import { Providers } from 'layouts/Providers';

import nextI18NextConfig from '../../next-i18next.config.js';

const PreviewIndicator = dynamic(() => import('common/general/PreviewIndicator'), {
  ssr: false,
});

const recklessNeueFont = localFont({
  src: '../fonts/RecklessNeue-Regular.woff2',
  variable: '--f-reckless-neue',
  display: 'swap',
});
const bigShouldersDisplayFont = localFont({
  src: '../fonts/BigShouldersDisplay-ExtraBold.woff2',
  variable: '--f-big-shoulders-display',
  display: 'swap',
});
const abcFavoritProFont = localFont({
  src: '../fonts/ABCFavoritProVariable.woff2',
  variable: '--f-abc-favorit-pro',
  display: 'swap',
});
const jvSignatureFont = localFont({
  src: '../fonts/JVSignature-Regular.woff2',
  variable: '--f-jv-signature',
  display: 'swap',
});

const fonts = [
  recklessNeueFont.variable,
  bigShouldersDisplayFont.variable,
  abcFavoritProFont.variable,
  jvSignatureFont.variable,
].join(' ');

const App = ({
  Component,
  pageProps: { dehydrateState, session, preview, showZendesk, ...pageProps },
}: Props) => {
  // This ensures that data is not shared between different users and requests,
  // while still only creating the QueryClient once per component lifecycle.
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 30 * 1000, // 30 seconds
            cacheTime: 1000 * 6 * 10, // 10 minutes
            retry: false,
          },
        },
      }),
  );

  const getLayout = Component.layout || ((page) => page);

  useZingfitRedirects();
  useRouteTracking();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=10" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" sizes="32x32" href="/favicon-32x32.png" />
      </Head>

      <Providers {...{ session, queryClient }}>
        <GlobalStyle />
        {showZendesk && <Zendesk />}
        <BackgroundContainer>
          <Hydrate state={dehydrateState}>
            <main className={fonts}>
              {getLayout(<Component {...pageProps} />, pageProps)}
              {preview && <PreviewIndicator />}
            </main>
          </Hydrate>
          <div id="modal" key="modal" className={fonts} />
          <div id="cookie-consent" key="cookie-consent" className={fonts} />
        </BackgroundContainer>
        <CookieBar />
      </Providers>
    </>
  );
};

type Props = Omit<AppProps, 'pageProps'> & {
  pageProps: i.AnyObject & {
    dehydrateState?: DehydratedState;
    session: Session;
    preview?: boolean;
    showZendesk?: boolean;
  };
  Component: i.NextPageComponent;
};

export default appWithTranslation(App, nextI18NextConfig);
