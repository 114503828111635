import * as React from 'react';

export const PageBackgroundContext = React.createContext<PageBackgroundContextProps | null>(null);

export const PageBackgroundProvider = ({ children }: PageBackgroundProviderProps) => {
  const [backgroundColor, setBackgroundColor] = React.useState<undefined | string>();
  const [overlayOpacity, setOverlayOpacity] = React.useState<undefined | number>();

  const changeBackgroundColor = (color?: string) => {
    setBackgroundColor(color);
  };

  const changeOverlayOpacity = (opacity?: number) => {
    setOverlayOpacity(opacity);
  };

  return (
    <PageBackgroundContext.Provider value={{
      changeBackgroundColor,
      changeOverlayOpacity,
      backgroundColor,
      overlayOpacity,
    }}>
      {children}
    </PageBackgroundContext.Provider>
  );
};

type PageBackgroundContextProps = {
  changeBackgroundColor: (color?: string) => void;
  changeOverlayOpacity: (opacity?: number) => void;
  backgroundColor?: string;
  overlayOpacity?: number;
}

export type PageBackgroundProviderProps = {
  children: React.ReactNode;
};
