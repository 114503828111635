import * as React from 'react';

import { PaymentContext } from 'services/context/paymentContext';

export const usePaymentContext = () => {
  const context = React.useContext(PaymentContext);

  if (!context) {
    throw new Error('Components should be rendered inside the PaymentContextProvider component');
  }

  return context;
};
