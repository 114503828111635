import * as i from 'types';

import { isInPast } from 'services/date';

const statusLabels = {
  'non-bookable': 'schedule.status.nonBookable',
  'cancelled': 'schedule.status.cancelled',
  'closed': 'schedule.status.closed',
  'full': 'schedule.status.full',
  'waitlist': 'schedule.status.waitlist',
  'almost-full': 'schedule.status.almostFull',
  'past': undefined,
  'available': undefined,
};

export const getStatusLabel = (status: ClassStatuses) => statusLabels[status];

export const getScheduleClassStatus: GetScheduleClassStatus = (bookableClass) => {
  if (!bookableClass.bookable) return 'non-bookable';

  const pastClass = isInPast(bookableClass.classDate);

  if (pastClass) return 'past';
  if (bookableClass.cancelled) return 'cancelled';
  if (bookableClass.closed) return 'closed';
  if (bookableClass.waitlistFull) return 'full';
  if (bookableClass.full) return 'waitlist';
  if (bookableClass.spotCount && bookableClass.enrolled && bookableClass.spotCount - bookableClass.enrolled <= 5) return 'almost-full';

  return 'available';
};

type ClassStatuses = 'past' | 'cancelled' | 'closed' | 'full' | 'waitlist' | 'available' | 'non-bookable' | 'almost-full';

type GetScheduleClassStatus = (bookableClass: i.ExtendedClass) => ClassStatuses;

