import * as i from 'types';
import { useSession } from 'next-auth/react';
import { useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash';

import api from 'services/api';
import { sendError } from 'services/sentry';

const getSeries = async (locationName?: string, accessToken?: string) => {
  const query = {
    city: locationName?.toLowerCase(),
  };

  const headers = {};

  if (accessToken) {
    headers['Authorization'] = `bearer ${accessToken}`;
  }

  return api
    .get<i.Series[]>({
      path: '/series',
      query,
      headers,
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error getting series',
        method: 'getSeries',
        endpoint: '/series',
        error,
        params: {
          query,
        },
      });
    });
};

const getSingleSeries = async (serieId?: string) => {
  return api
    .get<i.Series>({
      path: `/series/${serieId}`,
      type: 'zingfit',
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error getting single series',
        method: 'getSingleSeries',
        endpoint: `/series/${serieId}`,
        error,
        params: {
          serieId,
        },
      });
    });
};

const getClassSeries = async (classId?: string) => {
  return api
    .get<i.UserSeries[]>({
      path: `/classes/${classId}/series`,
      type: 'zingfit',
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error getting class series',
        method: 'useGetClassSeries',
        endpoint: `/classes/${classId}/series`,
        error,
        params: {
          classId,
        },
      });
    });
};

export const useGetSeries = (locationName?: string) => {
  const session = useSession();

  return useQuery<i.Series[] | null, i.ZingfitApiError, i.Series[]>({
    queryKey: ['series', locationName],
    queryFn: () => getSeries(locationName, session.data?.accessToken),
    enabled: Boolean(locationName),
  });
};

export const useGetGroupedSeries = (locationName?: string) => {
  const session = useSession();

  return useQuery<i.Series[] | null, i.ZingfitApiError, i.SeriesGroupedType | undefined>({
    queryKey: ['series', 'grouped', locationName],
    queryFn: () => getSeries(locationName, session.data?.accessToken),
    enabled: Boolean(locationName),
    select: (series) => {
      if (!series) return undefined;

      const groupedSeriesByMembershipsSeries = series.reduce(
        (acc, groupedSeries) => {
          const seriesCategory = groupedSeries.contract ? 'memberships' : 'packages';
          const series = acc[seriesCategory] ? acc[seriesCategory] : [];

          return {
            ...acc,
            [seriesCategory]: [...series, groupedSeries],
          };
        },
        {
          packages: [],
          memberships: [],
        } as { packages: i.Series[]; memberships: i.Series[] },
      );
      const groupedSeries = {
        packages: groupBy(groupedSeriesByMembershipsSeries.packages, (item) => item.seriesTypeId),
        memberships: groupBy(
          groupedSeriesByMembershipsSeries.memberships,
          (item) => item.seriesTypeId,
        ),
      };

      return groupedSeries;
    },
  });
};

export const useGetSeriesByOrder = (order?: i.PaymentOrder | null) => {
  const serieId = order?.seriesOrderItems?.[0].seriesId;

  return useQuery<i.Series | null, i.ZingfitApiError>({
    queryKey: ['series', serieId],
    queryFn: () => getSingleSeries(serieId),
    enabled: Boolean(serieId) && !Boolean(order?.orderItems),
  });
};

export const useGetClassSeries = (classId?: string) => {
  return useQuery<i.UserSeries[] | null, i.ZingfitApiError, i.BookingClassSeries | null>({
    queryKey: ['series', 'class', classId],
    queryFn: () => getClassSeries(classId),
    enabled: Boolean(classId),
    select: (series) => {
      if (series == null) {
        return null;
      }

      return {
        series,
        availableCredits: series.reduce((acc, cur) => acc + cur.classesRemaining, 0),
      };
    },
  });
};
