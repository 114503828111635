import * as i from 'types';
import { useQuery } from '@tanstack/react-query';

import api from 'services/api';
import { getUserIp } from 'services/ip';
import { sendError } from 'services/sentry';

/** Fetch the user's geolocation from their IP address */
export const useGeolocation = () => {
  return useQuery({
    queryKey: ['geolocation'],
    queryFn: async () => {
      const trace = await getUserIp();

      if (!trace) return null;

      const geolocation = api
        .get<i.GeopluginResponse>({
          path: '/geo',
          type: 'next',
          headers: {
            'x-ip': trace.ip,
          },
        })
        .catch((error) => {
          sendError({
            type: 'api',
            message: 'Error getting geolocation',
            method: 'useGeolocation',
            endpoint: '/geo',
            error,
            params: {
              trace,
            },
          });
        });

      return geolocation;
    },
    staleTime: Infinity, // Never refetch
  });
};
