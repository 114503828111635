import * as i from 'types';
import { NextRouter } from 'next/router';

const PAYMENT_URL = process.env.NEXT_PUBLIC_PAYMENT_URL;
const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

export const getPaymentUrl: GetPaymentUrl = ({
  userData,
  order,
  paymentInfo,
  paymentMethod,
  location,
}) => {
  if (!order || !paymentInfo || !userData || !paymentMethod || !location) return null;

  const url = new URL(`${PAYMENT_URL}/pay/${paymentMethod}`);
  url.searchParams.set('address', userData.address);
  url.searchParams.set('city', userData.city);
  url.searchParams.set('postcode', userData.zip);
  url.searchParams.set('country', userData.measure2);
  url.searchParams.set('apiKey', paymentInfo.gateway.stripeKey);
  url.searchParams.set('accountKey', paymentInfo.gateway.stripeAccount);
  url.searchParams.set('firstName', order.billingAddress.firstName);
  url.searchParams.set('lastName', order.billingAddress.lastName);
  url.searchParams.set('amount', String(order.amountDue.amount * 100));
  url.searchParams.set('orderId', order.id);
  url.searchParams.set('origin', 'web');
  url.searchParams.set('locale', 'en');
  url.searchParams.set('redirectUrl', `${SITE_URL}${location.asPath}`);
  url.searchParams.set('isExpress', String(paymentInfo.gateway.stripeConnectExpress));

  if (paymentInfo.gateway.testMode) {
    url.searchParams.set('testMode', 'true');
  }

  return url.toString();
};

export const get3DSecureAuthUrl: Get3DSecureAuthUrl = ({
  order,
  paymentInfo,
  paymentIntentClientSecret,
  location,
}) => {
  if (!order || !paymentInfo || !location) return null;

  const url = new URL(`${PAYMENT_URL}/pay/processing`);
  url.searchParams.set('origin', 'web');
  url.searchParams.set('locale', 'en');
  url.searchParams.set('firstName', order.billingAddress.firstName);
  url.searchParams.set('lastName', order.billingAddress.lastName);
  url.searchParams.set('apiKey', paymentInfo.gateway.stripeKey);
  url.searchParams.set('accountKey', paymentInfo.gateway.stripeAccount);
  url.searchParams.set('zingKey', paymentInfo.gateway.stripeZingKey);
  url.searchParams.set('isExpress', String(paymentInfo.gateway.stripeConnectExpress));
  url.searchParams.set('threeDSecureAuth', 'true');
  url.searchParams.set('redirectUrl', `${SITE_URL}${location.asPath.split('?')[0]}`);
  url.searchParams.set('paymentIntentClientSecret', paymentIntentClientSecret);

  if (paymentInfo.gateway.testMode) {
    url.searchParams.set('testMode', 'true');
  }

  return url.toString();
};

export const getGiftCardTransaction = (order?: i.PaymentOrder) =>
  order?.transactions
    ? order.transactions.filter((transaction) => transaction.paymentType === 'Gift Card')
    : [];

export const getStoreCreditTransaction = (order?: i.PaymentOrder) =>
  order?.transactions
    ? order.transactions.filter((transaction) => transaction.paymentType === 'Store Credit')
    : [];

export const getStoreCreditAmount = (paymentInfo?: i.PaymentInfo): i.Price => {
  if (!paymentInfo?.availableStoreCredits) return { amount: 0, currency: 'EUR' };

  const balances = paymentInfo.availableStoreCredits.map((storeCredits) => storeCredits.balance);
  const amount = balances.reduce((acc, cur) => acc + cur.amount, 0);

  if (balances.length === 0) return { amount: 0, currency: 'EUR' };

  return { amount, currency: balances[0].currency };
};

type GetPaymentUrl = (info: {
  userData?: i.User;
  order?: i.PaymentOrder | null;
  paymentInfo?: i.PaymentInfo | null;
  paymentMethod?: i.PaymentMethod;
  location?: NextRouter;
}) => null | string;

type Get3DSecureAuthUrl = (info: {
  order?: i.PaymentOrder;
  paymentInfo?: i.PaymentInfo;
  paymentIntentClientSecret: string;
  location?: NextRouter;
}) => null | string;
