import * as i from 'types';
import * as React from 'react';

import { isServer } from 'services/isServer';
import { getRandomTextureWithPositions } from 'services/textures';

export const useRandomTexture = () => {
  const [mounted, setMounted] = React.useState(false);
  const [texture, setTexture] = React.useState<i.RandomTextureWithPositions>();

  React.useEffect(() => {
    if (!isServer) {
      setMounted(true);
    }
  }, []);

  React.useEffect(() => {
    if (mounted && texture == null) {
      setTexture(getRandomTextureWithPositions());
    }
  }, [mounted, texture]);

  return texture;
};
