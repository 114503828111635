import * as React from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const useSize = (target: React.RefObject<HTMLDivElement>) => {
  const [size, setSize] = React.useState<Size>({
    width: 0,
    height: 0,
  });

  /**
   * This will cause a warning on the server. We can ignore it for now, as the warning will be removed in a later version of React.
   * @see https://github.com/facebook/react/pull/26395
   */
  React.useLayoutEffect(() => {
    const boundingRect = target.current?.getBoundingClientRect();

    setSize({
      width: boundingRect?.width || 0,
      height: boundingRect?.height || 0,
    });
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));

  return [size?.width, size?.height];
};

type Size = {
  width: number;
  height: number;
};
