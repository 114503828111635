import * as i from 'types';
import dayjs from 'dayjs';

import { getScheduleClassStatus } from './class';
import { fixDate, formatUnix, isSameDay } from './date';

export const filterScheduleClasses = (
  classes: i.ExtendedClass[],
  filters: i.ClassesFilters,
  activeDate: string,
) => {
  const { time, type, studios, instructor } = filters;
  let filteredClasses = classes?.filter((ride) => isSameDay(ride.classDate, activeDate)) || [];

  if (time.length > 0) {
    const now = dayjs(activeDate);

    const time6am = now.hour(6).minute(0);
    const time12pm = now.hour(12).minute(0);
    const time5pm = now.hour(17).minute(0);
    const time9pm = now.hour(21).minute(0);

    let morningClasses: i.Class[] = [];
    let afternoonClasses: i.Class[] = [];
    let eveningClasses: i.Class[] = [];

    if (time.includes('morning')) {
      morningClasses = filteredClasses.filter((bookableClass) =>
        dayjs(fixDate(bookableClass.classDate)).isBetween(time6am, time12pm, 'hour', '(]'),
      );
    }

    if (time.includes('afternoon')) {
      afternoonClasses = filteredClasses.filter((bookableClass) =>
        dayjs(fixDate(bookableClass.classDate)).isBetween(time12pm, time5pm, 'hour', '(]'),
      );
    }

    if (time.includes('evening')) {
      eveningClasses = filteredClasses.filter((bookableClass) =>
        dayjs(fixDate(bookableClass.classDate)).isBetween(time5pm, time9pm, 'hour', '[]'),
      );
    }

    filteredClasses = [...morningClasses, ...afternoonClasses, ...eveningClasses];
  }

  if (type.length > 0) {
    filteredClasses = filteredClasses.filter((bookableClass) =>
      type.includes(bookableClass.classTypeId),
    );
  }

  if (studios.length > 0) {
    filteredClasses = filteredClasses.filter((bookableClass) =>
      studios.includes(bookableClass.siteId),
    );
  }

  if (instructor.length > 0) {
    filteredClasses = filteredClasses.filter((bookableClass) =>
      instructor.includes(bookableClass.instructorId),
    );
  }

  // Sort all filtered classes by date
  return filteredClasses
    .filter((item) => !['non-bookable', 'past'].includes(getScheduleClassStatus(item)))
    .sort((a, b) => formatUnix(a.classDate) - formatUnix(b.classDate));
};

export const filterClassSubFilters = (classes?: i.ExtendedClass[]) => {
  const allClasses = classes || [];

  const uniqueAndSortFilter = (items: i.FilterItem[]) =>
    items
      .filter((v, i, a) => a.findIndex((t) => t.key === v.key) === i)
      .sort((a, b) => a.label.localeCompare(b.label));

  const type = uniqueAndSortFilter(
    allClasses.map((singleClass) => ({
      key: singleClass.classTypeId,
      label: singleClass.classType,
    })),
  );

  const instructor = uniqueAndSortFilter(
    allClasses.map((singleClass) => ({
      key: singleClass.instructorId,
      label: singleClass.instructorName,
    })),
  );

  const studios = uniqueAndSortFilter(
    allClasses.map((singleClass) => ({
      key: singleClass.siteId,
      label: singleClass.site || (singleClass.siteName as string),
    })),
  );

  return {
    type,
    instructor,
    studios,
  };
};
