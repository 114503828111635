import * as React from 'react';
import Link from 'next/link';

import { StyledButton, StyledButtonLoader, ButtonIcon } from './styled';
import { ButtonProps } from './types';

const ButtonLoader = () => (
  <StyledButtonLoader>
    <div />
    <div />
    <div />
  </StyledButtonLoader>
);

const ButtonContent: React.FC<ButtonProps> = ({ children, icon, $isLoading }) => (
  <>
    {$isLoading ? (
      <ButtonLoader />
    ) : (
      <>
        {icon && <ButtonIcon>{icon}</ButtonIcon>}
        <span>{children}</span>
      </>
    )}
  </>
);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      href,
      icon,
      $iconPosition = 'left',
      size = 'auto',
      $isLoading,
      onClick,
      disabled,
      variant,
      $iconOnlyOnMobile,
      ...otherProps
    },
    ref,
  ) => {
    const styledButtonProps = {
      disabled,
      $iconOnly: !children && icon,
      $iconOnlyOnMobile,
      $iconPosition,
      $isLoading,
      variant,
      size,
      ref,
    };

    const buttonContentProps = {
      children,
      icon,
      $isLoading,
    };

    if (href) {
      return (
        <StyledButton as={Link} href={href} {...styledButtonProps} {...otherProps}>
          <ButtonContent {...buttonContentProps} />
        </StyledButton>
      );
    }

    return (
      <StyledButton onClick={onClick} {...styledButtonProps} {...otherProps}>
        <ButtonContent {...buttonContentProps} />
      </StyledButton>
    );
  },
);
