import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { useGetContentfulInstructorThumbnail } from 'queries/contentful/instructors';
import { getRandomTexture } from 'services/textures';

export const useInstructorThumbnail = ({ id, name = 'Instructor', size }: InstructorThumbnail) => {
  const { t, i18n } = useTranslation();
  const instructorFallbackImage = React.useRef(getRandomTexture()).current;
  const instructorThumbnail = useGetContentfulInstructorThumbnail(i18n.language, id);

  return {
    image: instructorThumbnail.data?.fields.thumbnail.fields,
    fallback: instructorFallbackImage,
    alt: t('Ride with {{ instructorName }}', { instructorName: name }),
  };
};

type InstructorThumbnail = {
  id?: string;
  name?: string;
  size?: number;
};
