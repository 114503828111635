import * as React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

export const LinkStyles = css<LinkProps>`
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-size: 14px;
  padding: 2px 0;
  text-transform: uppercase;
  border: none;
  background: linear-gradient(currentColor 0 0) var(--p, 0) 100% / var(--d, 0) 1px no-repeat;
  transition: 0.3s, background-position 0s 0.3s;
  cursor: pointer;

  ${({ $isBold }) =>
    $isBold &&
    css`
      font-weight: 600;
    `}

  ${({ $isItalic }) =>
    $isItalic &&
    css`
      font-style: italic;
    `}

  ${({ $withIcon }) =>
    $withIcon &&
    css`
      display: flex;
      align-items: center;

      > svg {
        width: 10px;
        margin-right: 6px;
      }

      > img {
        margin-right: 6px;
      }
    `}

  ${({ $variant }) =>
    $variant === 'reckless' &&
    css`
      font-family: ${({ theme }) => theme.fonts.recklessNeue};
    `}

  ${({ $withoutTextTransform }) =>
    $withoutTextTransform &&
    css`
      text-transform: none;
    `}

  &:hover {
    --d: 100%;
    --p: 100%;
  }

  ${({ $underline }) =>
    $underline &&
    css`
      text-decoration: underline;

      &:hover {
        --d: 0;
        --p: 0;
      }
    `}
`;

export const StyledLink = styled(Link).attrs((props: LinkProps) => ({
  rel: props.target === '_blank' ? 'noopener noreferrer' : '',
}))<LinkProps>`
  ${LinkStyles}
`;

type LinkProps = React.ComponentProps<'a'> & {
  $isBold?: boolean;
  $withIcon?: boolean;
  as?: 'a' | 'button';
  $variant?: 'reckless';
  $withoutTextTransform?: boolean;
  $isItalic?: boolean;
  $underline?: boolean;
};
