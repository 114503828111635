import styled, { css } from 'styled-components';

export const BackgroundOverlay = styled.div<Props>`
  display: flex;
  flex-direction: column;
  transition: background-color 0.4s 0.4s;
  position: relative;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `}

  ${({ $overlayOpacity }) =>
    typeof $overlayOpacity === 'number' &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: ${$overlayOpacity};
        transition: opacity 0.6s ease;
        pointer-events: none;
      }
    `}
`;

type Props = {
  $backgroundColor?: string;
  $overlayOpacity?: number;
};
