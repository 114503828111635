import * as React from 'react';

import { PageBackgroundContext } from 'services/context/pageBackgroundContext';

export const usePageBackground = () => {
  const context = React.useContext(PageBackgroundContext);

  if (!context) {
    throw new Error(
      'Components should be rendered inside the PageBackgroundContext.Provider component',
    );
  }

  return context;
};
