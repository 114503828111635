export const useScrollTo = () => {
  const scrollTo = (selector: string, offset?: number, isSmooth?: boolean) => {
    const element = document.querySelector(selector);
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;

    if (element) {
      const yOffset = element?.getBoundingClientRect().top + scrollTop - clientTop;

      if (yOffset) {
        window.scrollTo({ top: yOffset - (offset || 0), behavior: isSmooth ? 'smooth' : 'auto' });
      }
    }
  };

  return {
    scrollTo,
  };
};
