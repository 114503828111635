import * as i from 'types';

import api from 'services/api';
import { sendError } from 'services/sentry';

export const bookClass = (payload: i.BookClassPayload) =>
  new Promise<void>((resolve, reject) => {
    const { selectedSeries, spotIds, classId } = payload;

    // Possible that multiple series is needed to fulfill a booking. We save the
    // used series' index in the list.
    let useableSeriesIndex = 0;
    let creditsLeft = selectedSeries[useableSeriesIndex]?.classesRemaining;

    // The user has enough credits, now we can get through the spotIds and make
    // the requests to reserve spots. Also, this must happen in sequence,
    // otherwise the server responds with a server error.
    const bookings = spotIds.reduce((previousPromise, nextSpotId) => {
      const currentSeries = selectedSeries[useableSeriesIndex];
      const body = {
        spotId: nextSpotId,
      } as { spotId: string; seriesItemId?: string };

      // If there are any series used for this booking, use it, if there aren't
      // the chance is high it's a free class that doesn't cost any credit
      if (currentSeries) {
        body.seriesItemId = currentSeries.id;
      }

      creditsLeft -= 1;

      // If there's no credits left in the currently iterated series and there's
      // a next series in the list, up the index to use next series
      if (!creditsLeft && selectedSeries[useableSeriesIndex + 1]) {
        useableSeriesIndex += 1;
        creditsLeft = selectedSeries[useableSeriesIndex].classesRemaining;
      }

      return previousPromise.then(() =>
        api.put({
          path: `/classes/${classId}/spots`,
          type: 'zingfit',
          body,
        }),
      );
    }, Promise.resolve());

    bookings
      .then(() => {
        resolve();
      })
      .catch((error) => {
        sendError({
          type: 'api',
          message: 'Error booking class',
          params: {
            endpoint: `/classes/${classId}/spots`,
            method: 'bookClass',
            classId,
            payload,
            creditsLeft,
            error,
          },
        });
        reject(error);
      });
  });
