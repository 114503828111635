import * as React from 'react';

import { isServer } from 'services/isServer';

let hidden = '';
let visibilityChange = '';

if (!isServer) {
  // Set the name of the hidden property and the change event for visibility
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if ((typeof document as any).mozHidden !== 'undefined') {
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
  } else if ((typeof document as any).msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if ((typeof document as any).webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
}

export const usePageVisibility = () => {
  const isTabHidden = !isServer ? document[hidden] : true;
  const [isVisible, setIsVisible] = React.useState<boolean>(!isTabHidden);

  const handleSetVisibility = () => {
    setIsVisible(!document[hidden]);
  };

  React.useEffect(() => {
    document.addEventListener(visibilityChange, handleSetVisibility, false);

    return () => {
      document.removeEventListener(visibilityChange, handleSetVisibility, false);
    };
  }, []);

  return isVisible;
};
