import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/nl';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.locale('en');
dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.extend(isBetween);

type DateType = string | Date;

export const defaultDateFormat = 'DD-MM-YYYY';
export const serverDateFormat = 'YYYY-MM-DD';
export const fullDateWithTime = 'DD MMMM YYYY, HH:mm';

export const isSameDay = (date1: DateType, date2: DateType): boolean => {
  if (!date1 || !date2) throw new Error('isSameDay: no date supplied');
  return dayjs(date1).isSame(dayjs(date2), 'day');
};

export const isToday = (date: DateType): boolean => {
  if (!date) throw new Error('isToday: no date supplied');
  return isSameDay(date, dayjs().toString());
};

export const isThisWeek = (date: DateType): boolean => {
  if (!date) throw new Error('isThisWeek: no date supplied');
  return daysCount(date) < 6;
};

export const isInPast = (date: DateType): boolean => {
  if (!date) throw new Error('isInPast: no date supplied');
  return dayjs(date).isBefore(dayjs());
};

export const formatTime = (date: DateType): string => {
  if (!date) throw new Error('formatTime: no date supplied');
  return dayjs(fixDate(date)).format('H:mm');
};

export const formatShortDayName = (date: DateType): string => {
  if (!date) throw new Error('formatShortDayName: no date supplied');
  return dayjs(date).format('ddd');
};

export const formatDayMonth = (date: DateType): string => {
  if (!date) throw new Error('formatDayMonth: no date supplied');
  return dayjs(date).format('DD-MM');
};

export const formatLongDayMonth = (date: DateType): string => {
  if (!date) throw new Error('formatLongDayMonth: no date supplied');
  return dayjs(fixDate(date)).format('D MMM');
};

export const formatDayFullMonth = (date: DateType): string => {
  if (!date) throw new Error('formatDayFullMonth: no date supplied');
  return dayjs(fixDate(date)).format('D MMMM');
};

export const formatUnix = (date: DateType): number => {
  if (!date) throw new Error('formatUnix: no date supplied');
  return dayjs(date).unix();
};

export const formatDayTime = (date: DateType): string => {
  if (!date) throw new Error('formatDayTime: no date supplied');
  return dayjs(fixDate(date)).format('dddd H:mm');
};

export const addHour = (date: DateType, amount: number): string => {
  if (!date) throw new Error('addHour: no date supplied');
  return dayjs(date).add(amount, 'hour').toString();
};

export const addMinute = (date: DateType, amount: number): string => {
  if (!date) throw new Error('addMinute: no date supplied');
  return dayjs(date).add(amount, 'minute').toString();
};

export const daysCount = (date: DateType): number => {
  if (!date) throw new Error('daysCount: no date supplied');
  return dayjs(date).hour(0).minute(0).diff(dayjs().hour(0).minute(0), 'day');
};

export const formatDayOrDate = (date: DateType): string => {
  if (!date) throw new Error('dayOrDate: no date supplied');

  if (isToday(date)) return `Today ${formatTime(date)}`;

  if (isThisWeek(date)) {
    return formatDayTime(date);
  } else {
    return `${formatDayMonth(date)} ${formatTime(date)}`;
  }
};

export const toDayjs = (date: DateType): Dayjs => {
  if (!date) throw new Error('No date provided to format');
  return dayjs(date);
};

export const toDate = (date: DateType): Date => {
  if (!date) throw new Error('No date provided to format');
  return dayjs(date).toDate();
};

export const formatDate = (date: DateType, format?: string): string => {
  if (!date) throw new Error('No date provided to format');
  return dayjs(date).format(format || defaultDateFormat);
};

export const stringToDate = (date: string, format?: string): Date => {
  const parsed = dayjs(date, format || defaultDateFormat, 'nl');
  const newDate = new Date(parsed.year(), parsed.month(), parsed.date());
  return newDate;
};

export const getWeekDay = (date?: DateType): number => {
  if (!date) return Number(dayjs().isoWeekday()) - 1;

  return Number(dayjs(date).isoWeekday()) - 1;
};

export const getComingDays = (numberOfDays: number): Date[] => {
  const now = new Date();

  const days = Array.from({ length: numberOfDays }, (_, days) => {
    const day = new Date(now);
    day.setDate(now.getDate() + days);
    return day;
  });

  return days;
};

// Zingfit sends date strings with 0 timezone offset (Zulu time) sometimes. This
// is bad. Really bad. This causes that time in our timezone will shift 1 or 2
// hours. If a date string contains the letter Z at the end of an UTC-formated
// date string, it means it has no timezone offset. To fix this, we need to
// remove this so the browser will know the date is already in our timezone.
export const fixDate = (date?: DateType) => {
  if (!date) throw new Error('No date provided to fixDate');

  if (typeof date === 'string' && date.endsWith('Z')) return date.slice(0, -1);

  return date;
};
