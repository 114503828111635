import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { hexToRgba } from 'services/style';
import { media } from 'styles/utils';

export const BlurBackgroundContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  backdrop-filter: blur(5px);
  background-color: ${({ theme }) => hexToRgba(theme.colors.white, '0.3')};
`;

export const Container = styled(motion.div)<ContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  bottom: 0;
  z-index: 5;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.springWood};

  ${media.desktop`
    width: 50vw;
  `}

  ${({ $disableScroll }) =>
    $disableScroll &&
    css`
      overflow-y: hidden;
    `};
`;

type ContainerProps = {
  $disableScroll?: boolean;
};
