import * as i from 'types';

/**
 * In order to get the translation of these messages, you need to pass one of
 * these error codes to the t() function of the translation handler
 */
export const errorMessages: Record<string, string> = {
  invalid_grant: 'message.error.invalidGrant',
  customer_not_found: 'message.error.customerNotFound',
  user_already_exists: 'message.error.userExists',
  not_enough_credits: 'message.error.notEnoughCredits',
  already_rated: 'message.error.alreadyRated',
  network_error: 'message.error.networkError',
  gift_card_not_found: 'message.error.giftCard.notFound',
  default: 'message.error.default',
  OVER_LIMIT: 'message.error.overReservationLimit',
  ORDERSTATUS_NOT_OPEN: 'message.error.overPaymentTrialLimit',
  TOO_EARLY: 'message.error.checkInTooEarly',
  NEW_CUSTOMER_ONLY: 'message.error.seriesForNewCustomersOnly',
  PURCHASE_LIMIT: 'message.error.overSeriesPurchaseLimit',
  NO_SERIES: 'message.error.notEnoughCredits',
  PROMO_CODE_NOT_VALID: 'message.error.promoCode.invalid',
  LIMIT: 'message.error.promoCode.usedMax',
  HAS_PROMO: 'message.error.promoCode.alreadyHasPromoCode',
  HAS_TRANSACTION: 'message.error.promoCode.alreadyHasTransaction',
  PROMOTION_APPLIED_DENIED: 'message.error.promoCode.couldNotBeApplied',
  GIFTCARD_REDEEM_APPLIED_TO_ORDER_ALREADY: 'message.error.giftCard.alreadyHasGiftCard',
};

/**
 * Zingfit API sends error messages does not come in a standardized format, we
 * need to parse them down to the copy we want to display
 */
export const getErrorMessage = (error: i.ZingfitApiError): string => {
  const foundErrorMessage = errorMessages[error?.error];

  // Return matching error message if found
  if (foundErrorMessage) return foundErrorMessage;

  // Special cases by Zingfit™
  if (/^(Network request failed)/.test(error?.message)) return errorMessages.network_error;
  if (/^(Customer has already rated class)/.test(error?.rateClass))
    return errorMessages.already_rated;
  if (/^(Customer not found)/.test(error?.error)) return errorMessages.customer_not_found;
  if (/^(The username already exists)$/.test(error?.username))
    return errorMessages.user_already_exists;
  if (/^(GiftCard)\s(.*)\s(.*)\s(not found)$/.test(error?.error))
    return errorMessages.gift_card_not_found;

  return errorMessages.default;
};
