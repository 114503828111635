import * as React from 'react';

import { BookingContext } from 'services/context/bookingContext';

export const useBookingSidebar = () => {
  const context = React.useContext(BookingContext);

  if (!context) {
    throw new Error('Components should be rendered inside the BookingContext.Provider component');
  }

  return context;
};
