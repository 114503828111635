import * as React from 'react';

export function useStoryCarousel(amountOfStories: number, delay = 10000) {
  const [currentStory, setCurrentStory] = React.useState(0);
  const interval = React.useRef<number | null>(null);
  const savedCallback = React.useRef<() => void>();

  const nextStory = () => {
    if (currentStory === amountOfStories) {
      setCurrentStory(0);
    } else {
      setCurrentStory(currentStory + 1);
    }
  };

  const clickStoryThumbnail = (index: number) => {
    setCurrentStory(index);

    if (interval.current !== null) {
      window.clearInterval(interval.current);
    }

    interval.current = window.setInterval(tick, delay);
  };

  const tick = () => {
    if (savedCallback?.current) {
      savedCallback.current();
    }
  };

  React.useEffect(() => {
    savedCallback.current = nextStory;
  }, [nextStory]);

  React.useEffect(() => {
    interval.current = window.setInterval(tick, delay);

    return () => {
      if (interval.current !== null) {
        window.clearInterval(interval.current);
      }
    };
  }, []);

  return { currentStory, clickStoryThumbnail };
}
