import * as React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import Script from 'next/script';

import { useCookies, useRouter } from 'hooks';

import { Container, Paragraph, StyledButton } from './styled';

export const CookieBar: React.FC = () => {
  const [isMounted, setMounted] = React.useState(false);
  const { t } = useTranslation();
  const { setCookie, isCookiesAccepted } = useCookies();
  const router = useRouter();

  React.useEffect(() => {
    setMounted(true);
  }, []);

  const cookieBarRoot = isMounted
    ? (document.getElementById('cookie-consent') as HTMLElement)
    : null;

  const handleAccept = () => {
    setCookie('gdpr_rocycle', 'true', { path: '/' });
    // Reloading the page is necessary to properly load the scripts
    router.reload();
  };

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <Head>
        {isCookiesAccepted && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
            }}
          />
        )}
        {isCookiesAccepted && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID});
            `,
            }}
          />
        )}
      </Head>
      {isCookiesAccepted && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_TRACKING_ID}`}
        />
      )}
      {!isCookiesAccepted &&
        ReactDOM.createPortal(
          <Container>
            <Paragraph>{t('This website uses cookies to enhance the user experience.')}</Paragraph>
            <StyledButton onClick={handleAccept}>{t('Accept')}</StyledButton>
          </Container>,
          cookieBarRoot as HTMLElement,
        )}
    </>
  );
};
