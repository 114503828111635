import * as React from 'react';

import * as fbq from 'services/fpixel';
import * as gtag from 'services/gtag';

import { useRouter } from './useRouter';

export const useRouteTracking = () => {
  const router = useRouter();

  React.useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    // Track pageviews
    const handleRouteChange = (url: string) => {
      fbq.pageview();
      gtag.pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
