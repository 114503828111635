import 'styled-components';

const theme = {
  colors: {
    white: Object.assign('#FFFFFF', {
      medium: '#FCFCFA',
      dark: '#F4F5F2',
      dirty: '#FAF7F5',
      floral: '#FDFCF1',
      azureish: '#D9E3EE',
    }),
    black: Object.assign('#000000', {
      light: '#4C4C4C',
    }),
    gray: Object.assign('#989898', {
      light: '#F4F4F4',
      medium: '#AAAAAA',
      pastel: '#CEDDBD',
    }),
    silver: '#C4C4C4',
    glory: '#F2EADE',
    text: '#262626',
    green: Object.assign('#82BC00', {
      light: '#c2c5a6',
      dark: '#61C41B',
    }),
    springWood: '#F6F2EE',
    red: '#DC3545',
    blue: Object.assign('#9999FF', {
      dark: '#1A6AFF',
    }),
    darkRed: '#DAC7B3',
    orange: '#FF6000',
    beige: Object.assign('#E6E1D8', {
      light: '#F7F2EE',
      dark: '#DED2BB',
      sand: '#E1C4AE',
    }),
    villa: '#EFEAE1',
    bluePurple: '#9999FF',
    purple: '#b395c4',
    orangeGrey: '#e1deda',
    yellow: '#ffc700',
    brown: '#B18357',
  },
  shadows: {
    interactiveElement: '0px 1px 1px 0px rgba(0,0,0,0.1)',
    overlay: '0px 2px 4px 0px rgba(0,0,0,0.1)',
  },
  fonts: {
    favoritPro: 'var(--f-abc-favorit-pro)',
    recklessNeue: 'var(--f-reckless-neue)',
    bigShouldersDisplay: 'var(--f-big-shoulders-display)',
    jvSignature: 'var(--f-jv-signature)',
  },
} as const;

export default theme;
