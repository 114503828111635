import * as i from 'types';
import { useQuery } from '@tanstack/react-query';

import { contentfulClient } from 'services';

export const fetchStudios = (
  locale: string,
  filters?: {
    location?: string;
  },
  preview?: boolean,
) => {
  return contentfulClient(preview).getEntries<i.TypeStudioFields>({
    content_type: 'studio',
    locale,
    ...(filters?.location && {
      'fields.location.sys.contentType.sys.id': 'location',
      'fields.location.fields.slug[match]': filters.location,
    }),
    select: [
      'fields.slug',
      'fields.name',
      'fields.openingTimesMonday',
      'fields.openingTimesTuesday',
      'fields.openingTimesWednesday',
      'fields.openingTimesThursday',
      'fields.openingTimesFriday',
      'fields.openingTimesSaturday',
      'fields.openingTimesSunday',
      'fields.location',
      'fields.coordinates',
      'fields.street',
      'fields.streetNumber',
      'fields.images',
      'sys',
    ].join(','),
  });
};

export const fetchStudio = async (
  locale: string,
  filters: i.FetchStudioFilter,
  preview?: boolean,
) => {
  const studios = await contentfulClient(preview).getEntries<i.TypeStudioFields>({
    content_type: 'studio',
    locale,
    limit: 1,
    ...(filters.zingfitId
      ? {
          'fields.zingfitId[match]': filters.zingfitId,
        }
      : filters.slug && {
          'fields.slug[match]': filters.slug,
        }),
  });

  return studios.items?.[0] || null;
};

export const useGetContentfulStudio = (locale: string, filters: i.FetchStudioFilter) => {
  return useQuery({
    queryKey: ['contentful', 'studios', locale, { ...filters }],
    queryFn: () => fetchStudio(locale, filters),
    staleTime: Infinity, // Doesn't need to refresh
    // One of the two filters has to be available
    enabled: Boolean(filters.slug || filters.zingfitId),
  });
};
