import * as i from 'types';
import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'services/api';
import { sendError } from 'services/sentry';

import { fetchClassAttendance } from './fetchClassAttendance';
import { fetchScheduleClasses } from './fetchScheduleClasses';

export const useGetScheduleClasses = (location?: i.TypeLocationFields, enabled = true) => {
  return useQuery({
    queryKey: ['classes', 'schedule', location?.slug],
    queryFn: () => fetchScheduleClasses(location),
    enabled: Boolean(location?.name && enabled),
    // 15 minutes. Heavy API call with lots of subcalls. We don't want to keep doing this.
    staleTime: 1000 * 60 * 15,
  });
};

export const useGetInstructorClasses = (instructorId?: string, limit = 3) => {
  return useQuery({
    queryKey: ['classes', 'instructor', instructorId],
    queryFn: async () => {
      return api
        .get<i.Class[]>({
          // @TODO This does not work anymore. Zingfit has killed this endpoint. We need a new endpoint from backend.
          path: `/instructors/${instructorId}/classes?limit=${limit}`,
          type: 'zingfit',
          withAuth: false,
        })
        .catch((error) => {
          sendError({
            type: 'api',
            message: 'Error getting instructor classes',
            method: 'useGetInstructorClasses',
            endpoint: `/instructors/${instructorId}/classes?limit=${limit}`,
            error,
            params: {
              instructorId,
              limit,
            },
          });

          return [];
        });
    },
    enabled: Boolean(instructorId),
  });
};

export const useGetStudioClasses = (studioId: string, limit = 3) => {
  return useQuery({
    queryKey: ['classes', 'studio', studioId],
    queryFn: () => {
      return api
        .get<i.Class[]>({
          // @TODO This does not work anymore. Response changed and limit does not work anymore. Backend needs to fix this.
          path: `/sites/${studioId}/classes?limit=${limit}`,
          type: 'zingfit',
          withAuth: false,
        })
        .catch((error) => {
          sendError({
            type: 'api',
            message: 'Error getting studio classes',
            method: 'useGetStudioClasses',
            endpoint: `/sites/${studioId}/classes?limit=${limit}`,
            error,
            params: {
              studioId,
              limit,
            },
          });
        });
    },
    enabled: Boolean(studioId),
  });
};

export const useGetClassAttendance = (classId?: string, studioId?: string) => {
  return useQuery<i.Attendance, i.ZingfitApiError, i.ClassExtension>({
    queryKey: ['classes', 'attendance', classId],
    queryFn: () => {
      return fetchClassAttendance(classId, studioId);
    },
    enabled: Boolean(studioId && classId),
    select: (attendance) => {
      return {
        spotCount: attendance.spotCount,
        enrolled: attendance.enrolled,
        waitlisted: attendance.waitlisted,
      };
    },
  });
};

export const useGetClass = (classId?: string) => {
  return useQuery<i.ClassDetail | null, i.ZingfitApiError>({
    queryKey: ['classes', classId],
    queryFn: async () => {
      return api
        .get<i.ClassDetail>({
          path: `/classes/${classId}/details`,
          type: 'zingfit',
        })
        .catch((error) => {
          return sendError({
            type: 'api',
            message: 'Error getting class',
            method: 'useGetClass',
            endpoint: `/classes/${classId}/details`,
            error,
            params: {
              classId,
            },
          });
        });
    },
    enabled: Boolean(classId),
  });
};

export const useCancelClass = () => {
  return useMutation<void[], i.ZingfitApiError, i.CancelClassPayload>({
    mutationFn: (payload) => {
      const cancelClassCalls = payload.attendanceIds.map((attendanceId) => {
        return api.post({
          path: `/account/classes/${attendanceId}/cancel`,
          type: 'zingfit',
          body: {
            lateCancel: payload.lateCancel.toString(),
          },
        });
      });

      return Promise.all(cancelClassCalls);
    },
  });
};
