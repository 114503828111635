export const getUserIp = async () => {
  return (
    fetch('https://www.cloudflare.com/cdn-cgi/trace')
      // The response is NOT json but a text file with key=value pairs
      .then((res) => res.text())
      .then((text) => {
        // Split by newline
        const data = text.split('\n').reduce((acc, kv) => {
          // split k=v and convert to object
          const [k, v] = kv.split('=');
          if (!v) return acc;

          return { ...acc, [k]: v };
        }, {} as CloudflareTraceResponse);

        return data;
      })
      .catch((err) => {
        console.error(err);
        return null;
      })
  );
};

type CloudflareTraceResponse = {
  fl: string;
  h: string;
  ip: string;
  ts: string;
  visit_scheme: string;
  uag: string;
  colo: string;
  sliver: string;
  http: string;
  loc: string;
  tls: string;
  sni: string;
  warp: string;
  gateway: string;
  rbi: string;
  kex: string;
};
