import * as i from 'types';
import { useSession } from 'next-auth/react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { getUniqueClasses } from 'queries/user/utils';
import api from 'services/api';
import { sendError } from 'services/sentry';

import { activeRewardsTestData, rewardsTestData } from './mock';

export const fetchMe = async () => {
  return api
    .get({
      path: '/account/me',
      type: 'zingfit',
    })
    .catch((error: i.ZingfitApiError) => {
      return sendError({
        type: 'api',
        message: 'Error getting account info',
        method: 'fetchMe',
        endpoint: '/account/me',
        error,
      });
    });
};

export const useGetMe = () => {
  const session = useSession();

  return useQuery<i.UserMe>({
    queryKey: ['me', 'account'],
    queryFn: fetchMe,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: session.status === 'authenticated',
  });
};

export const useEditMe = () => {
  const queryClient = useQueryClient();

  return useMutation<i.UserMe, i.ZingfitApiError, i.EditMeFormData>({
    mutationFn: async (formData) => {
      return api
        .put({
          path: '/account/me',
          type: 'zingfit',
          body: formData,
        })
        .catch((error: i.ZingfitApiError) => {
          return sendError({
            type: 'api',
            message: 'Error updating account info',
            method: 'useEditMe',
            endpoint: '/account/me',
            error,
            params: {
              formData,
            },
          });
        });
    },
    onSettled(data) {
      if (data) {
        queryClient.invalidateQueries(['me', 'account']);
      }
    },
  });
};

export const fetchUserClasses = async (
  page = 0,
  searchParams?: URLSearchParams,
  session?: i.UserSession,
) => {
  return api
    .get<i.MyClasses>({
      path: `/account/classes?${searchParams}`,
      type: 'zingfit',
    })
    .catch((error: i.ZingfitApiError) => {
      sendError({
        type: 'api',
        message: 'Error getting user classes',
        method: 'fetchUserClasses',
        endpoint: `/account/classes?${searchParams}`,
        error,
        params: {
          page,
          searchParams: searchParams?.toString(),
          session,
        },
      });

      return {} as i.MyClasses;
    });
};

export const useGetUserClasses = (size = '100') => {
  const session = useSession();

  const searchParams = new URLSearchParams({
    size,
    page: '0',
  });

  return useInfiniteQuery({
    queryKey: ['me', 'classes', size],
    queryFn: ({ pageParam = 0 }) => {
      return fetchUserClasses(pageParam, searchParams, session);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.page.pageNumber + 1;
    },
  });
};

export const useGetUserPastClasses = () => {
  // We need to fetch all classes
  // 2000 is the limit
  // See: https://labela.slack.com/archives/CSFSX3T6H/p1689249144867359?thread_ts=1688974907.380829&cid=CSFSX3T6H
  const userClasses = useGetUserClasses('2000');

  return {
    ...userClasses,
    data: userClasses.data?.pages?.[0]?.content.filter((c) => {
      // Enrolled means it happened or has not happened yet and is not cancelled
      if (c.status !== 'Enrolled') {
        return false;
      }

      // Filter out future classes
      if (dayjs(c.classDate).isAfter(dayjs(), 'minute')) {
        return false;
      }

      return true;
    }),
  };
};

export const useGetUpcomingClasses = () => {
  const userClasses = useGetUserClasses();
  const myClasses = userClasses.data
    ? {
        ...userClasses.data,
        content: userClasses.data.pages
          .map((page) =>
            page.content.map((userClass) => ({
              ...userClass,
              site: userClass.siteName,
            })),
          )
          .flat(),
      }
    : null;
  const upcomingClasses = myClasses
    ? myClasses.content
        .filter(
          (myClass) =>
            (myClass.status === 'Enrolled' || myClass.status === 'Waitlisted') &&
            dayjs(myClass.classDate).isAfter(dayjs(), 'minute'),
        )
        .reverse()
    : [];

  return { ...userClasses, data: upcomingClasses };
};

export const useGetUpcomingUniqueClasses = () => {
  const upcomingClasses = useGetUpcomingClasses();
  return getUniqueClasses(upcomingClasses.data);
};

export const useGetPastClasses = () => {
  const userClasses = useGetUserClasses();

  const myClasses =
    userClasses.data?.pages.map((page) => page.content).flat() || ([] as i.MyClass[]);

  return userClasses.data
    ? myClasses.filter((myClass) => dayjs(myClass.classDate).isBefore(dayjs(), 'minute'))
    : ([] as i.MyClass[]);
};

export const useGetPastUniqueClasses = () => {
  const pastClasses = useGetPastClasses();
  return getUniqueClasses(pastClasses);
};

export const fetchUserRewards = async (session?: i.UserSession) => {
  return api
    .get<i.Reward[]>({
      path: '/account/rewards',
      type: 'zingfit',
    })
    .then((data) => {
      // Zingfit does not return this data on any non production environment
      if (!__PROD__) {
        return rewardsTestData;
      }

      return data;
    })
    .catch((error: i.ZingfitApiError) => {
      sendError({
        type: 'api',
        message: 'Error getting user rewards',
        method: 'fetchUserRewards',
        endpoint: '/account/rewards',
        error,
        params: {
          session,
        },
      });

      return [] as i.Reward[];
    });
};

export const useGetUserRewards = () => {
  const session = useSession();

  return useQuery({
    queryKey: ['me', 'rewards'],
    queryFn: () => fetchUserRewards(session),
  });
};

export const fetchUserActiveRewards = async (session?: i.UserSession) => {
  return api
    .get<i.Reward[]>({
      path: '/account/rewards/active',
      type: 'zingfit',
    })
    .then((data) => {
      // Zingfit does not return this data on any non production environment
      if (!__PROD__) {
        return activeRewardsTestData;
      }

      return data;
    })
    .catch((error: i.ZingfitApiError) => {
      sendError({
        type: 'api',
        message: 'Error getting user active rewards',
        method: 'fetchUserActiveRewards',
        endpoint: '/account/rewards/active',
        error,
        params: {
          session,
        },
      });

      return [] as i.Reward[];
    });
};

export const useGetUserActiveRewards = () => {
  const session = useSession();

  return useQuery({
    queryKey: ['me', 'rewards', 'active'],
    queryFn: () => fetchUserActiveRewards(session),
  });
};

export const registerUser = async (data: i.UserRegisterData) => {
  const body = {
    ...data,
    // Hotfix for the registration flow. Different ID's for test and production
    preferredSiteId: process.env.NEXT_PUBLIC_PREFER_SITE_ID,
  };

  return api
    .post({
      path: '/account/register',
      type: 'zingfit',
      withAuth: false,
      body,
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error registering user',
        method: 'registerUser',
        endpoint: '/account/register',
        error,
        params: {
          data,
          body,
        },
      });
    });
};

export const useRegisterUser = () => {
  return useMutation({
    mutationFn: (data: i.UserRegisterData) => registerUser(data),
  });
};

export const requestForgotPassword = (email: string) => {
  const body = {
    username: email,
  };

  return api
    .post({
      path: '/account/forgot-password',
      type: 'zingfit',
      withAuth: false,
      body,
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error requesting forgot password',
        method: 'requestForgotPassword',
        endpoint: '/account/forgot-password',
        error,
        params: {
          email,
          body,
        },
      });
    });
};

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: (email: string) => requestForgotPassword(email),
  });
};

export const requestResetPassword = async (newPassword: string, token: string) => {
  const body = {
    token,
    password: newPassword.trim(),
  };

  return api
    .post({
      path: '/account/reset-password',
      type: 'zingfit',
      withAuth: false,
      body,
    })
    .catch((error) => {
      return sendError({
        type: 'api',
        message: 'Error requesting reset password',
        method: 'requestResetPassword',
        endpoint: '/account/reset-password',
        error,
        params: {
          token,
          body,
        },
      });
    });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: (data: { newPassword: string; token: string }) =>
      requestResetPassword(data.newPassword, data.token),
  });
};