import * as React from 'react';

import { lockBodyScroll } from 'services/lockBodyScroll';

export const useBodyScrollLock = () => {
  const [isScrollLocked, setScrollLock] = React.useState(false);

  React.useEffect(() => {
    lockBodyScroll(isScrollLocked);

    return () => {
      lockBodyScroll(false);
    };
  }, [isScrollLocked]);

  return { isScrollLocked, setScrollLock };
};
