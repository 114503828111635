import * as i from 'types';
import { useQuery } from '@tanstack/react-query';

import { fetchInstructors } from 'queries/contentful/instructors';
import { fetchStudios } from 'queries/contentful/studios';
import { contentfulClient } from 'services/contentfulClient';

export const fetchLocations = async (locale: string, preview?: boolean) => {
  return contentfulClient(preview).getEntries<i.TypeLocationFields>({
    content_type: 'location',
    locale,
  });
};

export const fetchHeaderLocations = async (
  locale: string,
  preview?: boolean,
): Promise<i.HeaderLocation[]> => {
  const [locations, studios, instructors] = await Promise.all([
    fetchLocations(locale, preview),
    fetchStudios(locale, {}, preview),
    fetchInstructors(locale, {}, preview),
  ]);

  return locations.items.map((location) => {
    return {
      ...location.fields,
      instructors: instructors.items.filter(
        (instructor) =>
          instructor.fields.location && instructor.fields.location.sys.id === location.sys.id,
      ).length,
      studios: studios.items.filter(
        (studio) => studio.fields.location && studio.fields.location.sys.id === location.sys.id,
      ).length,
    };
  });
};

export const fetchLocation = async (slug: string, locale: string, preview?: boolean) => {
  const locations = await contentfulClient(preview).getEntries<i.TypeLocationFields>({
    content_type: 'location',
    'fields.slug[match]': slug,
    locale,
    limit: 1,
  });

  return locations.items?.[0] || null;
};

export const useGetContentfulLocations = (locale: string) => {
  return useQuery({
    queryKey: ['contentful', 'locations', locale],
    queryFn: () => fetchLocations(locale),
    staleTime: Infinity, // Doesn't need to refresh
  });
};
