import React from 'react';
import Head from 'next/head';

import { isServer } from 'services';

export const Zendesk: React.FC = () => {
  React.useEffect(() => {
    if (!isServer) {
      const observer = new MutationObserver((_, me) => {
        const launcher = document.getElementById('launcher');
        if (launcher) {
          // Show zendesk helper
          window.zE?.show?.();
          me.disconnect(); // stop observing
          return;
        }
      });

      // start observing
      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      // Hide zendesk helper when unmounting
      window.zE?.hide?.();
    };
  }, []);

  return (
    <Head>
      <script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_TOKEN}`}
        async
        defer
      />
    </Head>
  );
};
