import * as i from 'types';

export const isOrderSuccessful = (order: i.PaymentOrder) => {
  if (!order) return false;

  const noAmountDue = order?.amountDue?.amount === 0 || false;
  const isOrderClosed = order.orderStatus === 'Closed';
  const hasSuccessfulTransaction = order.transactions?.some((transaction) => {
    // If the user pays with gift card and the order status gets closed, the
    // payment is considered successful, even though the transaction.successful
    // tells otherwise.
    return (
      transaction.successful === true || (isOrderClosed && transaction.paymentType === 'Gift Card')
    );
  });

  return noAmountDue && hasSuccessfulTransaction && isOrderClosed;
};

// Handles payment response based on what we get back from Zingfit. The message has
// lots of user errors which needs to be handled correctly
export const processPaymentResponse = (order: i.PaymentOrder): Promise<void> =>
  new Promise((resolve, reject) => {
    // Check if there's a transaction made
    if (!order.transactions?.length) {
      return reject({
        message: "The payment couldn't be finished",
        code: 'no_transaction',
      });
    }

    // The latest transaction is always the last one in the transactions array
    const latestTransaction = order?.transactions?.[order?.transactions?.length - 1];

    // If the transaction was successful, we did the work here
    if (latestTransaction?.successful) {
      return resolve();
    } else {
      // Parse weird message/code format from order
      const [message, code] = latestTransaction?.gatewayReasonText.split(';');

      // If the status appeared to be a payment error, the transaction cannot
      // be finished, we need the user to restart the payment process
      if (order.orderStatus === 'PaymentError' || order.orderStatus === 'Payment Error') {
        return reject({
          message: `${message} Please verify your payment details and start the payment process again.`,
          code: 'restart_payment',
        });
      } else {
        return reject({
          message,
          code: code.substr(6),
        });
      }
    }
  });
