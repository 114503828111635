import * as React from 'react';

import { usePageBackground } from 'hooks';

import { BackgroundOverlay } from './styled';

export const BackgroundContainer: React.FC<Props> = ({ children }) => {
  const { backgroundColor, overlayOpacity } = usePageBackground();

  return (
    <BackgroundOverlay $backgroundColor={backgroundColor} $overlayOpacity={overlayOpacity}>
      {children}
    </BackgroundOverlay>
  );
};

type Props = {
  children: React.ReactNode;
};
