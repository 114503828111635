import * as i from 'types';
import * as React from 'react';

import { getLocalData, removeLocalData, setLocalData } from 'services/asyncStorage';
import storageFiltersAreValid from 'services/storageFiltersAreValid';

const storageKey = 'classes::filter';
const defaultFilters: i.ClassesFilters = { time: [], type: [], instructor: [], studios: [] };

export const useBookingFilters = () => {
  const getCurrentDate = () => new Date().toString();
  const [activeDate, setActiveDate] = React.useState(getCurrentDate());
  const onResetActiveDate = () => {
    setActiveDate(getCurrentDate());
  };

  const [filterOpen, setFilterOpen] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState(defaultFilters);

  const onSetActiveFilters = (filters: i.ClassesFilters) => {
    setActiveFilters(filters);
    setLocalData(storageKey, JSON.stringify(filters));
  };

  const resetActiveFilters = () => {
    setActiveFilters(defaultFilters);
    removeLocalData(storageKey);
  };

  React.useEffect(() => {
    const storageFilters = getLocalData(storageKey);
    const activeFilters = storageFilters && JSON.parse(storageFilters);

    if (activeFilters) {
      // Check if local storage filter object is valid before we save it to redux
      if (storageFiltersAreValid(activeFilters)) {
        setActiveFilters(activeFilters);
      } else {
        // If local storage filter object is not valid, remove it
        removeLocalData(storageKey);
      }
    }
  }, []);

  return {
    filterOpen,
    setFilterOpen,
    activeFilters,
    setActiveFilters: onSetActiveFilters,
    resetActiveFilters,
    activeDate,
    setActiveDate,
    resetActiveDate: onResetActiveDate,
  };
};
