import * as React from 'react';

import { useCurrentLocation, useRouter } from 'hooks';

export const useFallbackCityRedirect = () => {
  const router = useRouter();
  const nearestCity = useCurrentLocation()?.currentLocation?.slug;

  React.useEffect(() => {
    if (nearestCity) {
      router.push(`${router.pathname}/${nearestCity?.toLowerCase()}`, undefined, { shallow: true });
    }
  }, [nearestCity]);
};
