/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureScope, withScope, captureException, type Scope } from '@sentry/react';

type LogType = {
  message?: string;
  [key: string]: any;
};

type ErrorType = {
  type: string;
  [key: string]: any;
};

/* Set extra information when there's a render error on site */
export const handleErrorBoundaryCapture = (scope: Scope) => {
  scope.setTag('url', window.location.href);
};

/* When the user is authenticated, inform sentry about the user */
export const setSentryUser = (email?: string) => {
  configureScope((scope: Scope) => {
    scope.setUser({ email });
  });
};

/* Send a non-error log to Sentry (useful for production debugging) */
export const sendLog = (log: LogType) => {
  if (!__PROD__) {
    /* eslint-disable no-console */
    console.warn('Sentry.Log', log);
  }

  withScope((scope: Scope) => {
    scope.setExtras({ log: JSON.stringify(log) });
  });
};

/* Send an error-level message to Sentry when it's a business logic error */
export const sendError = (error: ErrorType) => {
  if (!__PROD__) {
    console.error('Sentry.Error', error);
  }

  withScope((scope: Scope) => {
    scope.setExtras({ error: JSON.stringify(error) });
    captureException(error.message || 'Undescribed error');
  });

  return null;
};
