import styled, { keyframes } from 'styled-components';

import { Button } from 'common/interaction';

const slideIn = keyframes`
  0% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
`;

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 20px;
  background-color: ${({ theme }) => theme.colors.beige};
  animation-name: ${slideIn};
  animation-duration: 0.3s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform: translateY(100%);
`;

export const Paragraph = styled.p`
  margin: 0 8px 0 0;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.favoritPro};
`;

export const StyledButton = styled(Button).attrs(() => ({
  type: 'button',
  size: 'auto',
  variant: 'secondary',
}))`
  opacity: 1;
`;
