import { signOut } from 'next-auth/react';

import { HandleStatusCodes } from './types';

/**
 * Handle api status codes
 * Return true to exit the request
 */
const handleStatusCodes: HandleStatusCodes = async (code) => {
  switch (code) {
    case 204:
      return true;
    case 401:
      await signOut();

      const url = new URL(`${process.env.NEXT_PUBLIC_SITE_URL}/app/login`);
      url.searchParams.set('callbackUrl', window.location.href);
      window.location.href = url.href;

      return true;
    case 403:
      return true;
    default:
      return false;
  }
};

export default handleStatusCodes;
