import * as i from 'types';
import { useCookies as useReactCookie } from 'react-cookie';
import { O } from 'ts-toolbelt';
import type { CookieSetOptions } from 'universal-cookie';

export const COOKIE = {
  GDPR: 'gdpr_rocycle',
  Location: 'location',
} as const;

type CookieNames = O.UnionOf<typeof COOKIE>;

// This checks if the "Cookies" type has all the required keys from COOKIE
type CookiesGenerator<T extends Record<CookieNames, any>> = T;

// These always need to have at least "undefined" as a possible value
type Cookies = CookiesGenerator<{
  gdpr_rocycle: string | undefined;
  location: i.TypeLocationFields | undefined;
}>;

export const useCookies = () => {
  const [cookies, setReactCookie] = useReactCookie<CookieNames, Cookies>(Object.values(COOKIE));

  // Improve type safety of setCookie of react-cookie
  const setCookie = <T extends CookieNames>(
    name: T,
    value: Cookies[T],
    options?: CookieSetOptions,
  ) => {
    return setReactCookie(name, value, options);
  };

  return {
    cookies,
    setCookie,
    // Keep this explicit to prevent users tempering with the value.
    // Only the "true" string should be valid.
    isCookiesAccepted: cookies.gdpr_rocycle === 'true',
  };
};
