import { getRandomInt } from 'services/mathHelpers';

export const urls = [
  '/images/texture/23.png',
  '/images/texture/26.png',
  '/images/texture/29.png',
  '/images/texture/32.png',
  '/images/texture/35.png',
  '/images/texture/38.png',
  '/images/texture/41.png',
  '/images/texture/44.png',
];

export type RandomTextureWithPositions = {
  url: string;
  x: string;
  y: string;
};

export const getRandomTexture = () => urls[getRandomInt(0, urls.length - 1)];
export const getRandomTextureWithPositions = (): RandomTextureWithPositions => ({
  url: getRandomTexture(),
  x: `${getRandomInt(0, 100)}%`,
  y: `${getRandomInt(0, 100)}%`,
});
